import React from "react";
import { Col, Form, Input, Row } from "reactstrap";

const GalleryHeader = () => {
  return (
    <div id="gallery-header">
      <Row className="align-items-center mb-3">
        <Col md="3" sm="12" className="or">
          <h6 className="text-dark fw-bold font-14">GALLERY</h6>
        </Col>
        <Col md="5" sm="12" className="o">
          <div className="form">
            <Form>
              <Input
                type="text"
                name="search"
                id="search"
                className="search py-2"
                placeholder="Search"
              />
            </Form>
          </div>
        </Col>
        <Col md="4" sm="12" className="o">
          <div className="sort-filter">
            <span className="filter__text mx-md-3 mx-sm-0">
              Filter by <i className="bi bi-chevron-down"></i>
            </span>
            <span className="filter__text">
              Sort by <i className="bi bi-chevron-down"></i>
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default GalleryHeader;
