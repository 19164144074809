import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
} from "reactstrap";
import * as actions from "../../store/actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ResetPwd = (props) => {
  const [password, setPassword] = useState("");
  const [c_password, setCPassword] = useState("");
  const [isPassword, setIsPassword] = useState(false);
  const [errorExist, setErrorExist] = useState(false);
  let history = useHistory();
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const { auth } = selector;
  const handleTogglePassword = () => {
    setIsPassword(!isPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if ( password === "" || password.length < 6 
    || c_password === "" || c_password.length < 6 
    || password !== c_password) {
      setErrorExist(true);
    } else {
       let token =  localStorage.getItem('token')
      const payload = { password,token  };
      setErrorExist(false);
      return await dispatch(actions.resetPwd(payload));
    }
  };

  useEffect(() => {
    if (
      auth.authData &&
      Object.keys(auth.authData).length &&
      !auth.authLoading
    ) {
      history.push("/auth/login");
    }
    if (auth.authErr && !auth.authLoading) {
      toast.error("Something went wrong! Kindly retry");
    }
  }, [auth, history]);

  let pwdType = isPassword ? "text" : "password";
  let loading = auth && auth.authLoading;
  return (
    <Form className="form-wrap">
      <h6 className="text-dark py-4 fw-bold">RESET PASSWORD</h6>
      <Col className="mb-3">
        <FormGroup>
          <Label
            className="info__label d-flex justify-content-between"
            for="password"
          >
            <span>New Password</span>
          </Label>
          {isPassword ? (
            <i
              class="bi bi-eye-slash-fill font-13 right-input__icon"
              onClick={handleTogglePassword}
            ></i>
          ) : (
            <i
              class="bi bi-eye-fill font-13 right-input__icon"
              onClick={handleTogglePassword}
            ></i>
          )}
          <Input
            type={pwdType}
            name="password"
            id="password"
            className="info__input"
            placeholder="New Password"
            value={password}
            required={true}
            onChange={(e) => setPassword(e.target.value)}
          />
          {password === "" && errorExist ? (
            <FormText className="font-12 text-danger">
              Password is required
            </FormText>
          ) : null}
          {password && errorExist && password.length < 6 && (
            <FormText className="font-12 text-danger">
              Password must be more than 6 characters
            </FormText>
          )}
        </FormGroup>
      </Col>
      <Col className="mb-3">
        <FormGroup>
          <Label
            className="info__label d-flex justify-content-between"
            for="password"
          >
            <span>Confirm Password</span>
          </Label>
          {isPassword ? (
            <i
              class="bi bi-eye-slash-fill font-13 right-input__icon"
              onClick={handleTogglePassword}
            ></i>
          ) : (
            <i
              class="bi bi-eye-fill font-13 right-input__icon"
              onClick={handleTogglePassword}
            ></i>
          )}
          <Input
            type={pwdType}
            name="c_password"
            id="c_password"
            className="info__input"
            placeholder="Confirm Password"
            value={c_password}
            required={true}
            onChange={(e) => setCPassword(e.target.value)}
          />
          {c_password === "" && errorExist ? (
            <FormText className="font-12 text-danger">
             Confirm Password is required
            </FormText>
          ) : null}
          {c_password && errorExist && c_password.length < 6 && (
            <FormText className="font-12 text-danger">
              Password must contain 6 or more characters
            </FormText>
          )}
          {password && c_password && c_password.length > 5 &&   c_password !== password  && (
            <FormText className="font-12 text-danger">
              Password and Confirm password must be the same
            </FormText>
          )}
        </FormGroup>
      </Col>
      <Col className="mb-3">
        <Button
          type="submit"
          className="btn d-flex align-items-center justify-content-center w-100 btn-pry text-white btnAuth__submit"
          onClick={handleSubmit}
        >
          {loading ? (
            <>
              <div
                className="spinner-border text-white"
                style={{ height: "1rem", width: "1rem", color: "white" }}
                role="status"
              >
                {/* <span class="sr-only">Loading...</span> */}
              </div>
              &nbsp; Processing...
            </>
          ): ' RESET PASSWORD'}
         
        </Button>
      </Col>
      <p className="font-12 text-center text-light">
        Already have an account?{" "}
        <Link to="/auth/login" className="text-dark">
          SignIn
        </Link>
      </p>
    </Form>
  );
};

export default ResetPwd;
