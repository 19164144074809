import { Post } from "../../apiServices/apiHandler.service";
import { AUTH_LOADING, AUTH_ERROR, AUTH_SUCCESS } from "./types";

export const signUpUser = (details, role) => {
  return async (dispatch) => {
    try {
     
      dispatch({ type: AUTH_LOADING, payload: true });
      dispatch({ type: AUTH_SUCCESS, payload: null });
      let URL = role === 'company' ? 'account' : role === 'user'?  'user-invite/accept' : null
      let response = null;
      // return console.log(details, role)
      response = await Post(URL, details);
      const { status, data } = response;
      if (status === 200) {
        response = data.data;
        localStorage.setItem("user", JSON.stringify(response.user));
        localStorage.setItem("token", response.access_token);
        dispatch({ type: AUTH_SUCCESS, payload: response });
        dispatch({ type: AUTH_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: AUTH_LOADING, payload: false });
      dispatch({ type: AUTH_ERROR, payload: error });
    }
  };
};

export const loginUser = (details) => {
  return async (dispatch) => {
    try {
      dispatch({ type: AUTH_LOADING, payload: true });
      dispatch({ type: AUTH_SUCCESS, payload: null });
      dispatch({ type: AUTH_ERROR, payload: null });
      let response = null;
      response = await Post(`auth/login`, details);
      const { status, data } = response;
      if (status === 200) {
        response = data.user;
        localStorage.setItem("token", data.access_token);
        localStorage.setItem("user", JSON.stringify(data.user));
        dispatch({ type: AUTH_SUCCESS, payload: response });
        dispatch({ type: AUTH_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: AUTH_LOADING, payload: false });
      dispatch({ type: AUTH_ERROR, payload: error.response });
    }
  };
};

export const forgotPwd = (details) => {
  return async (dispatch) => {
    try {
      dispatch({ type: AUTH_LOADING, payload: true });
      dispatch({ type: AUTH_SUCCESS, payload: null });
      dispatch({ type: AUTH_ERROR, payload: null });
      let response = null;
      response = await Post(`auth/forgot-password`, details);
      const { status } = response;
      if (status === 200) {
        dispatch({ type: AUTH_SUCCESS, payload: response });
        dispatch({ type: AUTH_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: AUTH_LOADING, payload: false });
      dispatch({ type: AUTH_ERROR, payload: error.response });
    }
  };
};

export const resetPwd = (details) => {
  return async (dispatch) => {
    try {
      dispatch({ type: AUTH_LOADING, payload: true });
      dispatch({ type: AUTH_SUCCESS, payload: null });
      dispatch({ type: AUTH_ERROR, payload: null });
      let response = null;
      response = await Post(`auth/reset-password`, details);
      const { status } = response;
      if (status === 200) {
        dispatch({ type: AUTH_SUCCESS, payload: response });
        dispatch({ type: AUTH_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: AUTH_LOADING, payload: false });
      dispatch({ type: AUTH_ERROR, payload: error.response });
    }
  };
};



export const logoutUser = () => {
  return async (dispatch) => {
    //  await Post(`auth/logout`, config());
    // dispatch({ type: AUTH_SUCCESS, payload: null });
    // dispatch({ type: AUTH_ERROR, payload: null });
    // dispatch({ type: AUTH_LOADING, payload: false });
    // localStorage.removeItem('token')
    // localStorage.removeItem('user')
    // history.push("/auth/login");
    // window.location.reload()
  };
};
