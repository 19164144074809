import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as actions from "../../../store/actions/uiAction";
import { Row, Col } from "reactstrap";
import "./gallery.scss";
import img1 from "../../assets/img/product-image.jpg";
import { useDispatch } from "react-redux";
import UploadProductModal from "../../modals/UploadProductModal";

const Gallery = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.handleMenuGallery(true));
    return () => {
      dispatch(actions.handleMenuGallery(false));
    };
  }, [dispatch]);

  return (
    <div id="product-media">
      <div className="my-4">
        <Row lg="4" md="3" sm="2" xs="1">
          <Col className="my-3 cont">
            <div className="media-box">
              <i className="bi bi-three-dots invisible"></i>
              <div className="upload-product-text">
                <span className="pointer" onClick={toggle}>
                  <i className="bi bi-plus-circle"></i> Add Media
                </span>
              </div>
            </div>
          </Col>
          {[...Array(10).keys()].map((o) => {
            return (
              <Col className="my-3 cont" key={o}>
                <div className="box">
                  <i className="bi bi-three-dots dot__icon pointer d-block text-right"></i>
                  <Link to={`/admin/1234/product/product-info`}>
                    <div className="image">
                      <img src={img1} alt="gallery" />
                    </div>
                  </Link>
                  <p className="m-0 font-12 fw-light text-center">
                    imagename.jpeg
                  </p>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
      <UploadProductModal isOpen={modal} toggle={toggle} />
    </div>
  );
};

export default Gallery;
