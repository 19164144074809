import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import { userData } from "../../utils/constants";

const ProductTab = () => {
  return (
    <div id="product-tab">
      <Nav className=" tab-top">
        <NavItem className="tab">
          <NavLink
            className="nav-link"
            to={`/admin/${userData().id}/product/product-info`}
          >
            INFO
          </NavLink>
        </NavItem>
        <NavItem className="tab">
          <NavLink
            className="nav-link"
            to={`/admin/${userData().id}/product/product-media`}
          >
            MEDIA
          </NavLink>
        </NavItem>
        <NavItem className="tab">
          <NavLink
            className="nav-link"
            to={`/admin/${userData().id}/product/product-experience`}
          >
            EXPERIENCE PAGE
          </NavLink>
        </NavItem>
        <NavItem className="tab">
          <NavLink
            className="nav-link"
            to={`/admin/${userData().id}/product/product-roles`}
          >
            ROLES
          </NavLink>
        </NavItem>
        <NavItem className="tab">
          <NavLink
            className="nav-link last"
            to={`/admin/${userData().id}/product/product-config`}
          >
            CONFIGURATION
          </NavLink>
        </NavItem>
      </Nav>
    </div>
  );
};

export default ProductTab;
