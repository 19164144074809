import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions";
import { Table } from "reactstrap";
import SettUsersHeader from "./SettUsersHeader";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { showAlert } from "../../utils/constants";

const SettPendUsers = () => {
  const [data, setData] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [, setIsDeleting] = useState(false)
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const { settings } = selector;
  const { pendingUsers, pendingUsersErr, userPendLoading } = settings;

  const removeUser = ({ target: { id } }) => {
      return showAlert(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning"
    ).then((result) => {
      if (result.isConfirmed) {
        setDeleteId(id);
        setIsDeleting(true)
        dispatch(actions.removePendingUsers(id))
        .then(res=>{
          if (res.status === 200) {
            dispatch(actions.getPendingUsers());
            setIsDeleting(false);
            toast.success("User deleted successfully");
          } else {
            toast.error("Something went wrong");
            setIsDeleting(false);
          }
        });
      }
    });
  };

  useEffect(() => {
    dispatch(actions.getPendingUsers());
    dispatch(actions.handleMenuSettings(true));
    return () => {
      dispatch(actions.handleMenuSettings(false));
    };
  }, [dispatch]);

  useEffect(() => {

    if (pendingUsers && Object.keys(pendingUsers).length) {
      setData(pendingUsers.data);
    }
    if (!pendingUsers && pendingUsersErr && !userPendLoading) {
      toast.error("Something went wrong");
    }
  }, [pendingUsers , pendingUsersErr , userPendLoading]);
  return (
    <>
      <SettUsersHeader />
      <div id="settings-teams">
        <div className="team-users">
          <div className="db-table mt-5">
            <Table className="border table-responsive" hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>EMAIL</th>
                  <th>STATUS</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data && data.length && !settings.userPendLoading ? (
                  data.map((user, i) => {
                    return (
                      <tr key={user?.["id"]}>
                        <td className="count">{i + 1}</td>
                        <td className="name pointer"> {user?.["email"]}</td>
                        <td className="count">Pending</td>
                        <td className="cta">
                          <Link
                            to="#"
                            className="text-danger"
                            id={user?.["id"]}
                            onClick={removeUser}
                          >
                            {deleteId && +deleteId === +user["id"] ? (
                              <div
                                class="spinner-border text-danger delete__loader"
                                role="status"
                              ></div>
                            ) : (
                              "Remove"
                            )}
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                ) :  null}
              </tbody>
            </Table>
            <div className="d-flex justify-content-center">
          {settings.userPendLoading ? (
            
              <div
                class="spinner-border text-success data__loader"
                role="status"
              ></div>
          
          ) : !data.length ? (
            "No data to show"
          ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettPendUsers;
