import FactoryCert from "../dashboard/factory/FactoryCert";
import FactoryList from "../dashboard/factory/FactoryList";
import FactoryProduct from "../dashboard/factory/FactoryProduct";
import FactoryStory from "../dashboard/factory/FactoryStory";
import Gallery from "../dashboard/gallery/Gallery";
import ProductConfig from "../dashboard/product/ProductConfig";
import ProductExperience from "../dashboard/product/ProductExperience";
import ProductInfo from "../dashboard/product/ProductInfo";
import ProductList from "../dashboard/product/ProductList";
import ProductMedia from "../dashboard/product/ProductMedia";
import ProductRoles from "../dashboard/product/ProductRoles";
import Products from "../dashboard/product/Products";
import SettBilling from "../dashboard/settings/SettBilling";
import SettBrand from "../dashboard/settings/SettBrand";
import SettGeneral from "../dashboard/settings/SettGeneral";
import SettInvoices from "../dashboard/settings/SettInvoices";
import SettTeams from "../dashboard/settings/SettTeams";
import SettUpdates from "../dashboard/settings/SettUpdates";
import SettUsers from "../dashboard/settings/SettUsers";
import SettInfo from "../dashboard/settings/SettInfo";
import SettSecurity from "../dashboard/settings/SettSecurity";
import Analytics from "../dashboard/analytics/Analytics";
import SettPendUsers from "../dashboard/settings/SettPendUsers";

const Routes = [
  {
    path: "/admin/:id/product/",
    exact: true,
    component: Products,
  },
  {
    path: "/admin/:id/product/product-list",
    exact: true,
    component: ProductList,
  },
  {
    path: "/admin/:id/product/product-info",
    exact: true,
    component: ProductInfo,
  },
  {
    path: "/admin/:id/product/product-media",
    exact: true,
    component: ProductMedia,
  },
  {
    path: "/admin/:id/product/product-experience",
    exact: true,
    component: ProductExperience,
  },
  {
    path: "/admin/:id/product/product-roles",
    exact: true,
    component: ProductRoles,
  },
  {
    path: "/admin/:id/product/product-config",
    exact: true,
    component: ProductConfig,
  },
  {
    path: "/admin/:id/factory/factory-list",
    exact: true,
    component: FactoryList,
  },
  {
    path: "/admin/:id/factory/factory-story",
    exact: true,
    component: FactoryStory,
  },
  {
    path: "/admin/:id/factory/factory-cert",
    exact: true,
    component: FactoryCert,
  },
  {
    path: "/admin/:id/factory/factory-made",
    exact: true,
    component: FactoryProduct,
  },
  {
    path: "/admin/:id/galery/gallery-view",
    exact: true,
    component: Gallery,
  },
  {
    path: "/admin/:id/settings/general",
    exact: true,
    component: SettGeneral,
  },
  {
    path: "/admin/:id/settings/teams",
    exact: true,
    component: SettTeams,
  },
  {
    path: "/admin/:id/settings/status-update",
    exact: true,
    component: SettUpdates,
  },
  {
    path: "/admin/:id/settings/brand",
    exact: true,
    component: SettBrand,
  },
  {
    path: "/admin/:id/settings/users",
    exact: true,
    component: SettUsers,
  },
  {
    path: "/admin/:id/settings/pending-users",
    exact: true,
    component: SettPendUsers,
  },
  {
    path: "/admin/:id/settings/invoices",
    exact: true,
    component: SettInvoices,
  },
  {
    path: "/admin/:id/settings/billing",
    exact: true,
    component: SettBilling,
  },
  {
    path: "/admin/:id/settings/info",
    exact: true,
    component: SettInfo,
  },
  {
    path: "/admin/:id/settings/security",
    exact: true,
    component: SettSecurity,
  },
  {
    path: "/admin/:id/analytics",
    exact: true,
    component: Analytics,
  },
];
export default Routes;
