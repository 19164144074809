import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { ListGroup } from "reactstrap";

const PostSidebar = ({ getNavState }) => {
  const [navState, setNavState] = useState("image");

  const handleSetNavState = ({ target }) => {
    setNavState(target.id);
    getNavState(target.id);
  };
  return (
    <div className="left-wrap">
      <ListGroup>
        <NavLink
          to="#"
          id="image"
          className={`list__link d-flex align-items-center fw-bold font-14 mt-4 ${
            navState === "image" && "actives"
          }`}
          onClick={handleSetNavState}
        >
          <i
            className={`sidebar__icon bi bi-image-fill ${
              navState === "image" && "actives"
            } `}
          ></i>
          &nbsp; Image
        </NavLink>
        <NavLink
          to="#"
          id="video"
          className={`list__link d-flex align-items-center fw-bold font-14 ${
            navState === "video" && "actives"
          } `}
          onClick={handleSetNavState}
        >
          <i
            className={`sidebar__icon bi bi-film ${
              navState === "video" && "actives"
            }`}
          ></i>
          &nbsp; Video
        </NavLink>
        <NavLink
          to="#"
          id="map"
          className={`list__link d-flex align-items-center fw-bold font-14 ${
            navState === "map" && "actives"
          } `}
          onClick={handleSetNavState}
        >
          <i
            className={`sidebar__icon bi bi-map-fill ${
              navState === "map" && "actives"
            }`}
          ></i>
          &nbsp; Map
        </NavLink>
        <NavLink
          to="#"
          id="timeline"
          className={`list__link d-flex align-items-center fw-bold font-14 ${
            navState === "timeline" && "actives"
          } `}
          onClick={handleSetNavState}
        >
          <i
            className={`sidebar__icon bi bi-flag-fill ${
              navState === "timeline" && "actives"
            }`}
          ></i>
          &nbsp; Timeline
        </NavLink>
        <NavLink
          to="#"
          id="milestone"
          className={`list__link d-flex align-items-center fw-bold font-14 ${
            navState === "milestone" && "actives"
          } `}
          onClick={handleSetNavState}
        >
          <i
            className={`sidebar__icon bi bi-bag-check-fill ${
              navState === "milestone" && "actives"
            }`}
          ></i>
          &nbsp; Milestone
        </NavLink>
        <NavLink
          to="#"
          id="text"
          className={`list__link d-flex align-items-center fw-bold font-14 ${
            navState === "text" && "actives"
          } `}
          onClick={handleSetNavState}
        >
          <i
            className={`sidebar__icon bi bi-file-earmark-font-fill ${
              navState === "text" && "actives"
            }`}
          ></i>
          &nbsp; Text
        </NavLink>
      </ListGroup>
    </div>
  );
};

export default PostSidebar;
