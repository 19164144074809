import {
  SET_MENU_GALLERY,
  SET_MENU_PRODUCT,
  SET_MENU_FACTORY,
  SET_MENU_SETTINGS,
} from "../actions/types";

const INTIAL_STATE = {
  isMenuGallery: false,
  isMenuProduct: false,
  isMenuFactory: false,
  isMenuSettings: false,
};

export const reducer = (state = INTIAL_STATE, actions) => {
  switch (actions.type) {
    case SET_MENU_GALLERY:
      const { isMenuGallery } = actions.payload;
      return {
        ...state,
        isMenuGallery,
      };
    case SET_MENU_PRODUCT:
      const { isMenuProduct } = actions.payload;
      return {
        ...state,
        isMenuProduct,
      };
    case SET_MENU_FACTORY:
      const { isMenuFactory } = actions.payload;
      return {
        ...state,
        isMenuFactory,
      };
    case SET_MENU_SETTINGS:
      const { isMenuSettings } = actions.payload;
      return {
        ...state,
        isMenuSettings,
      };
    default:
      return { ...state };
  }
};

export default reducer;
