import {
  INVITE_USER_LOADING,
  INVITE_USER_SUCCESS,
  INVITE_USER_ERROR,
  USER_SUCCESS,
  USER_ERROR,
  USER_LOADING,
  USER_EDIT_SUCCESS,
  USER_EDIT_ERROR,
  USER_EDIT_LOADING,
  PENDING_USER_SUCCESS,
  PENDING_USER_ERROR,
  PENDING_USER_LOADING,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_ERROR,
  UPDATE_ROLE_LOADING,
  COMPANY_DETAILS_SUCCESS,
  COMPANY_DETAILS_ERROR,
  COMPANY_DETAILS_LOADING,
  TEAM_SUCCESS,
  TEAM_ERROR,
  TEAM_LOADING,
  TEAM_EDIT_SUCCESS,
  TEAM_EDIT_ERROR,
  TEAM_EDIT_LOADING,
  STATUS_UPDATES_SUCCESS,
  STATUS_UPDATES_ERROR,
  STATUS_UPDATES_LOADING,
} from "../actions/types";

const INTIAL_STATE = {
  addUsers: {},
  pendingUsers: {},
  settingsLoading: false,
  editedUserLoading: false,
  updatedStatusLoading: false,
};

export const reducer = (state = INTIAL_STATE, actions) => {
  switch (actions.type) {
    case INVITE_USER_SUCCESS:
      return {
        ...state,
        addUsers: actions.payload,
      };
    case INVITE_USER_ERROR:
      return { ...state, addUserErr: actions.payload };
    case INVITE_USER_LOADING:
      return { ...state, settingsLoading: actions.payload };

    case USER_SUCCESS:
      return {
        ...state,
        users: actions.payload,
      };
    case USER_ERROR:
      return { ...state, usersErr: actions.payload };
    case USER_LOADING:
      return { ...state, userLoading: actions.payload };

    case TEAM_SUCCESS:
      return {
        ...state,
        teams: actions.payload,
      };
    case TEAM_ERROR:
      return { ...state, teamsErr: actions.payload };
    case TEAM_LOADING:
      return { ...state, teamsLoading: actions.payload };

    case USER_EDIT_SUCCESS:
      return {
        ...state,
        editedUser: actions.payload,
      };
    case USER_EDIT_ERROR:
      return { ...state, editedUserErr: actions.payload };
    case USER_EDIT_LOADING:
      return { ...state, editedUserLoading: actions.payload };

    case COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        companyDetails: actions.payload,
      };
    case COMPANY_DETAILS_ERROR:
      return { ...state, companyDetailsErr: actions.payload };
    case COMPANY_DETAILS_LOADING:
      return { ...state, companyDetailsLoading: actions.payload };

    case TEAM_EDIT_SUCCESS:
      return {
        ...state,
        editedTeam: actions.payload,
      };
    case TEAM_EDIT_ERROR:
      return { ...state, editedTeamErr: actions.payload };
    case TEAM_EDIT_LOADING:
      return { ...state, editedTeamLoading: actions.payload };

    case PENDING_USER_SUCCESS:
      return {
        ...state,
        pendingUsers: actions.payload,
      };
    case PENDING_USER_ERROR:
      return { ...state, pendingUsersErr: actions.payload };
    case PENDING_USER_LOADING:
      return { ...state, userPendLoading: actions.payload };

    case UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        updateUser: actions.payload,
      };
    case UPDATE_ROLE_ERROR:
      return { ...state, updateUserErr: actions.payload };
    case UPDATE_ROLE_LOADING:
      return { ...state, updateUserLoading: actions.payload };

    case STATUS_UPDATES_SUCCESS:
      return {
        ...state,
        updatedStatus: actions.payload,
      };
    case STATUS_UPDATES_ERROR:
      return { ...state, updatedStatusErr: actions.payload };
    case STATUS_UPDATES_LOADING:
      return { ...state, updatedStatusLoading: actions.payload };

    default:
      return { ...state };
  }
};

export default reducer;
