import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Col,
  Label,
  Form,
  FormText,
  Input,
} from "reactstrap";
import { useDispatch } from "react-redux";
import * as actions from "../../store/actions";
import { toast } from "react-toastify";

const InviteTeamModal = ({ isOpen, toggle }) => {
  const [team, setTeam] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorExist, setErrorExist] = useState(false);
  const dispatch = useDispatch();

  const getFormData = () => {
    return {
      name: team,
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (team === "") {
      setErrorExist(true);
    } else {
      let payload = getFormData();
      setErrorExist(false);
      setIsLoading(true);
      return await dispatch(actions.inviteTeam(payload)).then((res) => {
        let { data, status } = res;
        if (status === 201 && data) {
          dispatch(actions.getTeams());
          toast.success("Team Sucessfully Invited");
          toggle();
          resetForm();
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error("Something went wrong! Kindly retry");
        }
      });
    }
  };

  const resetForm = () => {
    setTeam("");
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        id="invite__user-modal"
        className="modal-dialog-centered"
      >
        <ModalHeader className="text-dark" toggle={toggle}>
          INVITE TEAM
        </ModalHeader>
        <ModalBody>
          <Form className="form-wrap">
            <Col className="mb-4">
              <FormGroup>
                <Label className="info__label pb-2" for="teamName">
                  TEAM NAME
                </Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  className="info__input"
                  placeholder="Enter Team Name"
                  onChange={(e) => setTeam(e.target.value)}
                />
                {team === "" && errorExist ? (
                  <FormText className="font-12 text-danger">
                    Team name is required
                  </FormText>
                ) : null}
              </FormGroup>
            </Col>
          </Form>
        </ModalBody>
        <ModalFooter className="justify-content-start">
          <Button className="btn-pry" onClick={handleSubmit}>
            {isLoading ? (
              <>
                <div
                  className="spinner-border text-white"
                  style={{ height: "1rem", width: "1rem", color: "white" }}
                  role="status"
                ></div>{" "}
                ADDING TEAM...
              </>
            ) : (
              "ADD TEAM"
            )}
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default InviteTeamModal;
