import React from 'react'
import RegisterForm from './RegisterForm'

const CRegister = () => {
  return (
   <RegisterForm />
  )
}

export default CRegister
