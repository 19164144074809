import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
} from "reactstrap";

const UploadProductModal = ({ isOpen, toggle }) => {
  return (
    <div id="product-add-media-modal">
      <Modal
        className="modal-dialog-centered modal-lg custom-prod-modal"
        isOpen={isOpen}
        toggle={toggle}
      >
        <ModalHeader className="border-0 pb-0" toggle={toggle}></ModalHeader>
        <ModalBody className="">
          <Form className="form-wrap h-100">
            <Row className="h-100">
              <Col md={7}>
                <div className="upload-wrap d-flex flex-direction-column justify-content-center h-100">
                  <div className="upload-box  d-flex justify-content-center flex-column align-items-center">
                    <span className="text-grey fw-bold d-flex">
                      {" "}
                      <i class="bi bi-plus-circle media__icon"></i> &nbsp;
                      upload or drag a file{" "}
                    </span>
                    <hr className="w-100 " />
                    <span className="text-grey fw-bold d-flex">
                      <i class="bi bi-card-image media__icon"></i> &nbsp; Select
                      from the gallery
                    </span>
                  </div>
                </div>
              </Col>
              <Col md={5}>
                <Col className="mb-2 mt-4">
                  <FormGroup>
                    <Input
                      type="select"
                      className="text-dark fw-bold info__input"
                      name="status"
                    >
                      <option>SELECT A STATUS</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col className="mb-2">
                  <FormGroup>
                    <Input
                      type="textarea"
                      placeholder="Write a comment"
                      className="info__input comment"
                      name="text"
                      id="exampleText"
                    />
                  </FormGroup>
                </Col>
                <Button className="btn-pry">ADD MEDIA</Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default UploadProductModal;
