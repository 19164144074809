import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Col,
  Label,
  Form,
  FormText,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import * as actions from "../../store/actions/settingsActions";
import { toast } from "react-toastify";

const AddTeamUserModal = ({ id, isOpen, toggle, editData }) => {
  const [, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorExist, setErrorExist] = useState(false);
  const selector = useSelector((state) => state);
  const dispatch = useDispatch();
  const { settings } = selector;
  const { users, usersErr, userLoading } = settings;

  const [userOptions, setUserOptions] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const getFormData = () => {
    return {
      users: selectedUsers.map((o) => o.value),
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedUsers.length) {
      setErrorExist(true);
    } else {
      let payload = getFormData();
      setErrorExist(false);
      setIsLoading(true);
      return await dispatch(actions.addTeamUser(payload, id)).then((res) => {
        let { data, status } = res;
        if (status === 200 && data) {
          dispatch(actions.getTeams());
          toast.success("Team User Sucessfully added");
          toggle();
          resetForm();
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error("Something went wrong! Kindly retry");
        }
      });
    }
  };

  const resetForm = () => {
    setSelectedUsers([]);
  };
  const handleChange = (selectedUsers) => {
    setSelectedUsers(selectedUsers);
  };

  useEffect(() => {
    dispatch(actions.getPendingUsers());
  }, [dispatch]);

  useEffect(() => {
    if (users && Object.keys(users).length) {
      setUsers(users.data);
      let formattedOptions = users.data.map((o) => {
        return { label: o.full_name, value: o.id };
      });
      setUserOptions(formattedOptions);
    }
    if (!users && usersErr && !userLoading) {
      toast.error("Something went wrong");
    }
  }, [users , usersErr, userLoading ]);
  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        id="invite__user-modal"
        className="modal-dialog-centered"
      >
        <ModalHeader className="text-dark" toggle={toggle}>
          ADD USER
        </ModalHeader>
        <ModalBody>
          <Form className="form-wrap">
            <Col className="mb-4">
              <FormGroup>
                <Label className="info__label pb-2" for="teamName">
                  TEAM NAME
                </Label>
                <Select
                  isMulti
                  value={selectedUsers}
                  onChange={handleChange}
                  options={userOptions}
                  className=" font-14"
                  placeholder="Select Team to add User to"
                />
                {!userOptions.length && errorExist ? (
                  <FormText className="font-12 text-danger">
                    Kindly one or more Users
                  </FormText>
                ) : null}
              </FormGroup>
            </Col>
          </Form>
        </ModalBody>
        <ModalFooter className="justify-content-start">
          <Button className="btn-pry" onClick={handleSubmit}>
            {isLoading ? (
              <>
                <div
                  className="spinner-border text-white"
                  style={{ height: "1rem", width: "1rem", color: "white" }}
                  role="status"
                ></div>{" "}
                ADDING USER...
              </>
            ) : (
              "ADD USER"
            )}
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddTeamUserModal;
