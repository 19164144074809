import React from "react";
// import { Link } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, Label } from "reactstrap";

const MilestonePostAdd = () => {
  return (
    <>
      <Col xl="4" md="4" sm="12" className=" mid-col">
        <Form className="form-wrap">
          <Col className="mt-4 mb-3">
            <FormGroup>
              <Label className="info__label" for="name">
                <i className="bi bi-info-circle info__icon"></i> YOUR MILESTONE
              </Label>
              <Input
                type="text"
                name="desc"
                id="desc"
                className="info__input"
                placeholder="Sweatshirt"
              />
            </FormGroup>
          </Col>
          <Col className=" mb-3">
            <FormGroup>
              <Label className="info__label" for="name">
                <i className="bi bi-info-circle info__icon"></i> COMMENT
              </Label>
              <Input
                type="textarea"
                name="desc"
                id="desc"
                className="info__input textarea"
                placeholder="Sweatshirt"
              />
            </FormGroup>
          </Col>
        </Form>
      </Col>

      <Col xl="6" md="6" sm="12" className="border-left p-0">
        <h6 className="text-dark m-4 fw-normal">Preview</h6>
        <div className="milestone-desc-wrap">
          <h1 className="count">2430</h1>
          <i class="bi bi-star-fill  star"></i>
          <p className="font-12 text-center desc fw-normal">
            We launched these sweaters in 2430 stores across the globe
          </p>
        </div>

        <Col className="mt-3 border-top col p-3 text-right">
          <Button className="btn-pry">ADD TO PAGE</Button>
        </Col>
      </Col>
    </>
  );
};

export default MilestonePostAdd;
