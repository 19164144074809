import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Col,
  Label,
  Form,
  FormText,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions/settingsActions";
import { toast } from "react-toastify";

const EditTeamModal = ({ isOpen, toggle, editData }) => {
  const [teamName, setName] = useState("");
  const [errorExist, setErrorExist] = useState(false);
  const selector = useSelector((state) => state);
  const dispatch = useDispatch();
  const { settings } = selector;

  const getFormData = () => {
    return {
      name: teamName,
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (teamName === "") {
      setErrorExist(true);
    } else {
      let payload = getFormData();
      setErrorExist(false);
      return await dispatch(actions.editTeam(payload, editData.id)).then(
        (res) => {
          let { data, status } = res;
          if (status === 200 && data) {
            dispatch(actions.getTeams());
            toast.success("Team Sucessfully updated");
            resetForm();
            toggle();
          } else {
            toast.error("Something went wrong! Kindly retry");
          }
        }
      );
    }
  };

  const resetForm = () => {
    setName("");
  };

  useEffect(() => {
    if (editData) {
      setName(editData.name || "");
    } else {
      resetForm();
    }
  }, [editData]);
  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        id="invite__user-modal"
        className="modal-dialog-centered"
      >
        <ModalHeader className="text-dark" toggle={toggle}>
          UPDATE TEAM
        </ModalHeader>
        <ModalBody>
          <Form className="form-wrap">
            <Col className="mb-4">
              <FormGroup>
                <Label className="info__label pb-2" for="teamName">
                  TEAM NAME
                </Label>
                <Input
                  type="text"
                  name="teamName"
                  id="teamName"
                  className="info__input"
                  placeholder="Enter Team Name"
                  value={teamName}
                  onChange={(e) => setName(e.target.value)}
                />
                {teamName === "" && errorExist ? (
                  <FormText className="font-12 text-danger">
                    Team Name is required
                  </FormText>
                ) : null}
              </FormGroup>
            </Col>
          </Form>
        </ModalBody>
        <ModalFooter className="justify-content-start">
          <Button className="btn-pry" onClick={handleSubmit}>
            {settings.editedTeamLoading ? (
              <>
                <div
                  className="spinner-border text-white"
                  style={{ height: "1rem", width: "1rem", color: "white" }}
                  role="status"
                ></div>{" "}
                UPDATING DETAILS...
              </>
            ) : (
              "UPDATE TEAM"
            )}
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditTeamModal;
