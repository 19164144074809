import React from 'react'
import RegisterForm from './RegisterForm'

const Register = () => {
  return (
  <RegisterForm />
  )
}

export default Register
