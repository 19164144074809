import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Col,
  Label,
  Form,
  FormText,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import * as actions from "../../store/actions/settingsActions";
import { ValidateEmail } from "../utils/constants";
import { toast } from "react-toastify";
import RolesSelection from "../dashboard/settings/RolesSelection";


const InviteUserModal = ({ isOpen, toggle }) => {
  const [email, setEmail] = useState("");
  const [roles, setRoles] = useState([]);
  const [teamOptions, setTeamOptions] = useState([])
  const [errorExist, setErrorExist] = useState(false);
  const [accessLevel, setAccessLevel] = useState(false);
  const [selectedTeams, setSelectedTeams] = useState("");

  const selector = useSelector((state) => state);
  const dispatch = useDispatch();
  const { settings } = selector;
  const { teams } = settings;
  const handleSwitch = (nextChecked) => {
    setAccessLevel(nextChecked);
  };

  const selectRoles = ({ target: { id } }) => {
    setRoles([...roles, id]);
  };

  const handleChange = (selectedTeams) => {
    setSelectedTeams(selectedTeams);
  };

  const getFormData = () => {
    return {
      emails: [email],
      roles: accessLevel ? ["admin"] : [...new Set(roles)],
      teams: selectedTeams.length ? selectedTeams.map(o => o.value) : [],
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === "" || (!accessLevel && !roles.length)) {
      setErrorExist(true);
    } else {
      let payload = getFormData();
      setErrorExist(false);
      return await dispatch(actions.inviteUser(payload));
    }
  };

  const resetForm = () => {
    setEmail("");
    setRoles([]);
    setAccessLevel(false);
  };

  useEffect(() => {
    dispatch(actions.getTeams());
  }, [dispatch]);
  useEffect(() => {
    if (
      settings.addUsers?.length &&
      settings.settingsLoading &&
      !settings.addUserErr
    ) {
      toast.success("User Sucessfully Invited");
      resetForm();
      toggle();
    }
    if (settings.addUserErr) {
      toast.error("Something went wrong! Kindly retry");
    }
  }, [
    settings.addUsers,
    settings.settingsLoading,
    settings.addUserErr,
    toggle,
  ]);

  useEffect(() => {
    if (teams && teams.data.length) {
      let formattedOptions = teams.data.map((o) => {
        return { label: o.name, value: o.id };
      });
      setTeamOptions(formattedOptions);
    }
  }, [teams]);

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        id="invite__user-modal"
        className="modal-dialog-centered"
      >
        <ModalHeader className="text-dark" toggle={toggle}>
          INVITE USER
        </ModalHeader>
        <ModalBody>
          <Form className="form-wrap">
            <Col className="mb-4">
              <FormGroup>
                <Label className="info__label pb-2" for="name">
                  EMAIl
                </Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  className="info__input"
                  placeholder="Write new Status"
                  onChange={(e) => setEmail(e.target.value)}
                />
                {email === "" && errorExist ? (
                  <FormText className="font-12 text-danger">
                    Email is required
                  </FormText>
                ) : null}
                {email && !ValidateEmail(email) && (
                  <FormText className="font-12 text-danger">
                    Must be a valid email address
                  </FormText>
                )}
              </FormGroup>
            </Col>
            <RolesSelection 
             setAccessLevel={setAccessLevel}
             accessLevel={accessLevel}
             selectRoles={selectRoles}
             handleSwitch={handleSwitch}
             />
            {email && !accessLevel && !roles.length && (
              <FormText className="font-12 text-danger">
                Must select at least one Access Level when the{" "}
                <strong>role is a User</strong>
              </FormText>
            )}
            <Col className="mb-4 mt-3">
              <FormGroup>
                <Label className="info__label pb-2" for="name">
                  TEAMS
                </Label>
                <Select
                  isMulti
                  value={selectedTeams}
                  onChange={handleChange}
                  options={teamOptions}
                  className=" font-14"
                  placeholder="Select Team to add User to"
                />
              </FormGroup>
            </Col>
          </Form>
        </ModalBody>
        <ModalFooter className="justify-content-start">
          <Button className="btn-pry" onClick={handleSubmit}>
            {settings.settingsLoading ? (
              <>
                <div
                  className="spinner-border text-white"
                  style={{ height: "1rem", width: "1rem", color: "white" }}
                  role="status"
                ></div>{" "}
                SENDING INVITE...
              </>
            ) : (
              " SEND INVITE"
            )}
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default InviteUserModal;
