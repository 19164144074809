import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import * as actions from "../../../store/actions/uiAction";
import { Form, Input, Table } from "reactstrap";
import "./factory.scss";
import { useDispatch } from "react-redux";

const FactoryList = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.handleMenuFactory(true));
    return () => {
      dispatch(actions.handleMenuFactory(false));
    };
  }, [dispatch]);
  return (
    <div id="factory-list">
      <div className="team-user-table">
        <Table className=" table-responsive" hover>
          <thead>
            <tr>
              <th>FACTORIES</th>
              <th className="prd__count">ASSIGNED PRODUCTS</th>
              <th>LOCATION</th>
              <th>
                {" "}
                <Form className="form-wrap">
                <Input
                  type="name"
                  name="name"
                  id="name"
                  className="info__input"
                  placeholder="Search"
                />
                </Form>
               
              </th>
            </tr>
          </thead>
          <tbody>
            {[1, 2, 3, 4].map((o) => {
              return (
                <tr>
                  <td>
                    <Link to="/admin/1234/factory/factory-story" className="name">
                      {" "}
                      JAMES MACOY
                    </Link>
                  </td>
                  <td className="prd__count">
                    <Link to="#" className="name">
                      {" "}
       
                      {" "}
                                    12
                    
                    </Link>
                  </td>
                  <td className="location text-dark fw-light">LAGOS, NIGERIA</td>
                  <td className="cta">Remove</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default FactoryList;
