import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../../store/actions/uiAction";
import { Button, Table } from "reactstrap";
import ProductTab from "./ProductTab";

const ProductRoles = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.handleMenuProduct(true));
    return () => {
      dispatch(actions.handleMenuProduct(false));
    };
  }, [dispatch]);
  return (
    <div id="product-roles">
      <ProductTab />
      <div className="role-header  border-bottom flex-xl-row flex-sm-column">
        <h6 className="title">
          ASSIGNED FACTORY: <b>Factory X</b>
        </h6>
        <div className="btn-wrap">
          <Button className="btn btn-sec mx-md-2">EDIT FACTORY PROFILE</Button>
          <Button className="btn btn-sec">CHANGE FACTORY</Button>
        </div>
      </div>
      <div className="role-details">
        <div className="team-users">
          <div className="btn-wrap my-3">
            <Button className="btn btn-sec mx-md-2">ADD A TEAM</Button>
            <Button className="btn btn-sec">ADD USER</Button>
          </div>
          <div className="db-table">
            <Table className="border table-responsive" hover>
              <thead>
                <tr>
                  <th>TEAM AND USERS</th>
                  <th>READ</th>
                  <th>WRITE</th>
                  <th>PUBLISHED</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="name">JAMES MACOY</td>
                  <td>
                    <i className="bi bi-check-circle "></i>
                  </td>
                  <td>
                    <i className="bi bi-check-circle "></i>
                  </td>
                  <td>
                    <i className="bi bi-check-circle "></i>
                  </td>
                  <td className="cta">Edit</td>
                  <td className="cta">Remove</td>
                </tr>
                <tr>
                  <td className="name">JAMES MACOY</td>
                  <td>
                    <i className="bi bi-check-circle "></i>
                  </td>
                  <td>
                    <i className="bi bi-check-circle "></i>
                  </td>
                  <td>{/* <i className="bi bi-check-circle "></i> */}</td>
                  <td className="cta">Edit</td>
                  <td className="cta">Remove</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>

        <div className="suppliers mt-5">
          <div className="header mb-3 d-flex justify-content-between align-items-center">
            <h6 className="m-0 title">SUPPLIERS</h6>

            <div className="suppliers-btn-wrap">
              <Button className="btn btn-sec">ADD SUPPLIER</Button>
            </div>
          </div>
          <div className="db-table">
            <Table className="border table-responsive" hover>
              <thead>
                <tr>
                  <th>TEAM AND USERS</th>
                  <th>READ</th>
                  <th>WRITE</th>
                  <th>PUBLISHED</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="name">JAMES MACOY</td>
                  <td>
                    <i className="bi bi-check-circle "></i>
                  </td>
                  <td>
                    <i className="bi bi-check-circle "></i>
                  </td>
                  <td>
                    <i className="bi bi-check-circle "></i>
                  </td>
                  <td className="cta">Edit</td>
                  <td className="cta">Remove</td>
                </tr>
                <tr>
                  <td className="name">JAMES MACOY</td>
                  <td>
                    <i className="bi bi-check-circle "></i>
                  </td>
                  <td>
                    <i className="bi bi-check-circle "></i>
                  </td>
                  <td>{/* <i className="bi bi-check-circle "></i> */}</td>
                  <td className="cta">Edit</td>
                  <td className="cta">Remove</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductRoles;
