import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Form, FormGroup, Input, Label, FormText } from "reactstrap";
import { ValidateEmail } from "../utils/constants";
import * as actions from "../../store/actions/authAction";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

const ForgotPwd = () => {
  const dispatch = useDispatch();
  const select = useSelector((state) => state);
  const { auth } = select;

  const [email, setEmail] = useState("");
  const [errorExist, setErrorExist] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === "") {
      setErrorExist(true);
    } else {
      const payload = { email };
      setErrorExist(false);
      await dispatch(actions.forgotPwd(payload));
    }
  };
  useEffect(() => {
    if (
      auth.authData &&
      Object.keys(auth.authData).length &&
      !auth.authLoading
    ) {
      toast.success("Request Successful! KIndly check your mail to proceed");
    }
    if (auth.authErr && auth.authErr.message === "Network Error") {
      return toast.error("Kindly check your network connection and retry");
    }
  }, [auth]);

  let loading = select.auth && select.auth.authLoading;
  return (
    <Form className="form-wrap">
      <h6 className="text-dark py-4 fw-bold">LET’S GET YOU A NEW PASSWORD:</h6>
      <Col className="mb-3">
        <FormGroup>
          <Label className="info__label" for="email">
            Email
          </Label>
          <Input
            type="email"
            name="email"
            id="email"
            className="info__input"
            placeholder="Enter email"
            value={email}
            required={true}
            onChange={(e) => setEmail(e.target.value)}
          />
          {email === "" && errorExist ? (
            <FormText className="font-12 text-danger">
              Email is required
            </FormText>
          ) : null}
          {email && !ValidateEmail(email) && (
            <FormText className="font-12 text-danger">
              Must be a valid email address
            </FormText>
          )}
        </FormGroup>
      </Col>

      <Col className="mb-4">
        <Link
          to="/admin/peumlg/product"
          className="btn d-block w-100 btn-pry text-white btnAuth__submit"
          onClick={handleSubmit}
        >
          {loading ? (
            <>
              <div
                className="spinner-border text-white"
                style={{ height: "1rem", width: "1rem", color: "white" }}
                role="status"
              >
                {/* <span class="sr-only">Loading...</span> */}
              </div>{" "}
              PROCESSING...
            </>
          ) : (
            " REQUEST NEW PASSWORD"
          )}
        </Link>
      </Col>
      <p className="font-12 text-center text-light">
        Return to{" "}
        <Link to="/auth/login" className="text-dark">
          Login
        </Link>
      </p>
    </Form>
  );
};

export default ForgotPwd;
