import { combineReducers } from "redux";
import authReducer from "./authReducer";
import settingsReducer from "./settingsReducer";
import uiReducer from "./uiReducer";

export default combineReducers({
  ui: uiReducer,
  auth: authReducer,
  settings: settingsReducer,
});
