import { userData } from "../components/utils/constants";

export const baseUrl = "https://api.staging.ormaapp.net/api";

export const config = () => {
  const token = localStorage.getItem("token");
  if (token !== "") {
    const AuthStr = "Bearer ".concat(token);
    const acct_id = userData()?.accounts?.[0]["id"];
    return {
      headers: {
        Authorization: AuthStr,
        "X-Account-Id": acct_id,
      },
    };
  }
  return null;
};

export const checkAuth = () => {
  let localData = JSON.parse(localStorage.getItem("user-details"));
  if (localData) {
    return true;
  } else {
    return false;
  }
};
