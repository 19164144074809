import {
  AUTH_LOADING,
  AUTH_ERROR,
  AUTH_SUCCESS,
} from "../actions/types";

const INTIAL_STATE = {
  authData: {},
  authLoading: false,
};

export const reducer = (state = INTIAL_STATE, actions) => {
  switch (actions.type) {
    case AUTH_SUCCESS:
      return {
        ...state,
        authData: actions.payload,
      };
    case AUTH_ERROR:
      return { ...state, authErr: actions.payload };
    case AUTH_LOADING:
      return { ...state, authLoading: actions.payload };
    default:
      return { ...state };
  }
};

export default reducer;
