import React from "react";
import { NavLink } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { userData } from "../../utils/constants";

const SettUsersHeader = () => {
  const id = userData().id;
  return (
    <div className="user-tabs">
      <Row>
        <Col md={6} xs={6} className="text-center">
          <NavLink
            to={`/admin/${id}/settings/users`}
            className="text-center d-block user-tab"
          >
            ALL USERS
          </NavLink>
        </Col>
        <Col md={6} xs={6} className="text-center">
          <NavLink
            to={`/admin/${id}/settings/pending-users`}
            className="text-center d-block user-tab"
          >
            PENDING INVITES
          </NavLink>
        </Col>
      </Row>
    </div>
  );
};

export default SettUsersHeader;
