import {
  SET_MENU_GALLERY,
  SET_MENU_PRODUCT,
  SET_MENU_FACTORY,
  SET_MENU_SETTINGS,
} from "./types";

export const handleMenuProduct = (isMenuProduct) => {
  return (dispatch) => {
    dispatch({
      type: SET_MENU_PRODUCT,
      payload: { isMenuProduct },
    });
  };
};
export const handleMenuGallery = (isMenuGallery) => {
  return (dispatch) => {
    dispatch({
      type: SET_MENU_GALLERY,
      payload: { isMenuGallery },
    });
  };
};

export const handleMenuFactory = (isMenuFactory) => {
  return (dispatch) => {
    dispatch({
      type: SET_MENU_FACTORY,
      payload: { isMenuFactory },
    });
  };
};

export const handleMenuSettings = (isMenuSettings) => {
  return (dispatch) => {
    dispatch({
      type: SET_MENU_SETTINGS,
      payload: { isMenuSettings },
    });
  };
};
  



