import Swal from "sweetalert2";

export const userData = () => JSON.parse(localStorage.getItem("user"));
export const handleMenu = (state) => {
  localStorage.setItem("menu", state);
  return state;
};
export const getLocalMenu = () => {
  let menu = localStorage.getItem("menu");
  return menu;
};

export const ValidateEmail = (email) => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?a?a?[a-g]/;
  return re.test(String(email).toLowerCase());
};

export const showAlert = (title, text, icon, isLoading) => {
  return Swal.fire({
    title,
    text,
    icon,
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
    allowEscapeKey: false,
    allowOutsideClick: false,
  
    didOpen: () => {
      isLoading &&   Swal.showLoading();
    },
  });
};