import axios from "axios";
import { baseUrl, config } from "./config.service";

export const Get = async (url) => {
  try {
    return await axios.get(`${baseUrl}/${url}`, config());
  } catch (error) {
    throw error;
  }
};

export const Post = async (url, data) => {
  try {
    return await axios.post(`${baseUrl}/${url}`, data, config());
  } catch (error) {
    throw error;
  }
};

export const Put = async (url, data) => {
  try {
    return await axios.put(`${baseUrl}/${url}`, data, config());
  } catch (error) {
    throw error;
  }
};

export const Delete = async (url, data) => {
  try {
    return await axios.delete(`${baseUrl}/${url}`, config());
  } catch (error) {
    throw error;
  }
};
