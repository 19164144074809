import React, { useEffect } from "react";
import * as actions from "../../../store/actions/uiAction";
import { Col, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import img from "../../assets/img/timeline.jpg";
import FactoryBanner from "./FactoryBanner";
import FactoryTab from "./FactoryTab";

const FactoryProduct = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.handleMenuFactory(true));
    return () => {
      dispatch(actions.handleMenuFactory(false));
    };
  }, [dispatch]);
  return (
    <>
      <FactoryBanner />
      <FactoryTab />
      <section id="products-made">
        <div className="products-made-wrap my-5">
          <Row md="3" sm="2" xs="1">
            {[1, 2, 3, 4, 5, 6].map((o) => {
              return (
                <Col className="my-3">
                  <div className="image">
                    <img src={img} alt="Product__image" />
                  </div>
                  <div className="desc">
                    <h6 className="m-0 font-13 fw-bold pt-2">
                      CAMICIOLA IN VISCOSE{" "}
                    </h6>
                    <p className="m-0 font-12 fw-light">
                      
                      GRIGIO MEDIO - 6771/100
                    
                    </p>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </section>
    </>
  );
};

export default FactoryProduct;
