import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory , useLocation } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
} from "reactstrap";
import { ValidateEmail } from "../utils/constants";
import "./auth.scss";
import * as actions from "../../store/actions/authAction";
import { toast } from "react-toastify";

const RegisterForm = (props) => {
  let companyReg = window.location.pathname.includes("company-register");
  const dispatch = useDispatch();
  const select = useSelector((state) => state);
  const { auth } = select;
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [company_name, setCompany_name] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [commAgree, setCommAgree] = useState(false);
  const [policyAgree, setPolicyAgree] = useState(false);
  let history = useHistory();
  const [errorExist, setErrorExist] = useState(false);

  const useQueryString = () => {
    const location = useLocation();
    return new URLSearchParams(location.search);
   }
   const queryString = useQueryString();

  const handleSubmit = async (e) => {
    e.preventDefault();
    let token = queryString.get("token");
    if (
      first_name === "" ||
      last_name === "" ||
      (email === "" && companyReg) ||
      password === "" ||
      password.length < 6 ||
      !commAgree ||
      !policyAgree
    ) {
      setErrorExist(true);
    } else {
      const payload = {
        first_name,
        last_name,
        email,
        password,
        name: companyReg ? company_name : "",
      };
      
      setErrorExist(false);
      if (companyReg) {
        await dispatch(actions.signUpUser(payload, "company"));
      } else {
        delete payload.name;
        delete payload.email;
        payload.token = token;
        console.log('pay', payload)
        await dispatch(actions.signUpUser(payload, "user"));
      }
       
    }
  };
  useEffect(() => {
    if (auth.authData && Object.keys(auth.authData).length) {
      history.push("/admin/tp4vt/product");
    }
    if (auth.authErr && auth.authErr.message === "Network Error") {
      return toast.error("Kindly check your network connection and retry");
    }
  }, [auth, history]);

  const [isPassword, setIsPassword] = useState(false);
  const handleTogglePassword = () => {
    setIsPassword(!isPassword);
  };
  let pwdType = isPassword ? "text" : "password";
  let loading = select.auth && select.auth.authLoading;

  return (
    <Form className="form-wrap">
      <h6 className="text-dark py-4 fw-bold">
        CREATE YOUR {companyReg && "COMPANY"} ACCOUNT
      </h6>
      <div className="name-col"></div>
      <Col className="mb-3">
        <FormGroup>
          <Label className="info__label" for="first_name">
            First Name
          </Label>
          <Input
            type="text"
            name="first_name"
            id="first_name"
            className="info__input"
            placeholder="First Name"
            value={first_name}
            required={true}
            onChange={(e) => setFirst_name(e.target.value)}
          />
          {first_name === "" && errorExist ? (
            <FormText className="font-12 text-danger">
              First name is required
            </FormText>
          ) : null}
        </FormGroup>
      </Col>
      <Col className="mb-3">
        <FormGroup>
          <Label className="info__label" for="last_name">
            Last Name
          </Label>
          <Input
            type="text"
            name="last_name"
            id="last_name"
            className="info__input"
            placeholder="Last Name"
            value={last_name}
            required={true}
            onChange={(e) => setLast_name(e.target.value)}
          />
          {last_name === "" && errorExist ? (
            <FormText className="font-12 text-danger">
              Last name is required
            </FormText>
          ) : null}
        </FormGroup>
      </Col>
      {companyReg && (
        <>
        <Col className="mb-3">
          <FormGroup>
            <Label className="info__label" for="company_name">
              Company Name
            </Label>
            <Input
              type="text"
              name="company_name"
              id="company_name"
              className="info__input"
              placeholder="Company Name"
              value={company_name}
              required={true}
              onChange={(e) => setCompany_name(e.target.value)}
            />
            {company_name === "" && errorExist ? (
              <FormText className="font-12 text-danger">
                Company name is required
              </FormText>
            ) : null}
          </FormGroup>
        </Col>

        <Col className="mb-3">
        <FormGroup>
          <Label className="info__label" for="email">
            Email
          </Label>
          <Input
            type="email"
            name="email"
            id="email"
            className="info__input"
            placeholder="Enter email"
            value={email}
            required={true}
            onChange={(e) => setEmail(e.target.value)}
          />
          {email === "" && errorExist ? (
            <FormText className="font-12 text-danger">
              Email is required
            </FormText>
          ) : null}
          {email && !ValidateEmail(email) && (
            <FormText className="font-12 text-danger">
              Must be a valid email address
            </FormText>
          )}
        </FormGroup>
      </Col>
      </>
      )}

      
      <Col className="mb-3">
        <FormGroup>
          <Label
            className="info__label d-flex justify-content-between"
            for="password"
          >
            <span>Password</span>
            <Link
              to="/auth/reset-password"
              className="text-light font-12 pointer"
            >
              Forgot your password?
            </Link>
          </Label>
          {isPassword ? (
            <i
              class="bi bi-eye-slash-fill font-13 right-input__icon"
              onClick={handleTogglePassword}
            ></i>
          ) : (
            <i
              class="bi bi-eye-fill font-13 right-input__icon"
              onClick={handleTogglePassword}
            ></i>
          )}
          <Input
            type={pwdType}
            name="password"
            id="password"
            className="info__input"
            placeholder="Password"
            value={password}
            required={true}
            onChange={(e) => setPassword(e.target.value)}
          />
          {password === "" && errorExist ? (
            <FormText className="font-12 text-danger">
              Password is required
            </FormText>
          ) : null}
          {password && errorExist && password.length < 6 && (
            <FormText className="font-12 text-danger">
              Password must be more than 6 characters
            </FormText>
          )}
        </FormGroup>
      </Col>

      <FormGroup check>
        <Label check className="font-12 text-light">
          <Input
            type="checkbox"
            className="font-15"
            onChange={(e) => setCommAgree(e.target.checked)}
          />{" "}
          I agree to receive communication by email and phone related to Orma
        </Label>
      </FormGroup>
      <FormGroup check>
        <Label check className="font-12 text-light mb-3">
          <Input
            type="checkbox"
            className="font-15"
            required={true}
            onChange={(e) => setPolicyAgree(e.target.checked)}
          />
          I have read and agree to the{" "}
          <Link to="#" className="text-dark text-underline">
            Privacy Policy of Orma
          </Link>
        </Label>
      </FormGroup>

      <Col className="mb-3">
        <Button
          type="submit"
          className="btn d-flex align-items-center justify-content-center w-100 btn-pry text-white btnAuth__submit"
          onClick={handleSubmit}
        >
          {loading && (
            <>
              <div
                className="spinner-border text-white"
                style={{ height: "1rem", width: "1rem", color: "white" }}
                role="status"
              >
                {/* <span class="sr-only">Loading...</span> */}
              </div>
              &nbsp;
            </>
          )}
          SIGN UP WITH ORMA
        </Button>
      </Col>
      {/* <Col className="mb-3">
        <Link
          to="/auth/login"
          className="btn d-block w-100 btn-pry text-white btnAuth__submit"
        >
          <i class="bi  bi-google  info__icon font-12"></i> SIGN UP WITH GOOGLE
        </Link>
      </Col> */}
      <p className="font-12 text-center text-light">
        Already have an account?{" "}
        <Link to="/auth/login" className="text-dark">
          Log in
        </Link>
      </p>
    </Form>
  );
}

export default RegisterForm;
