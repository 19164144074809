import React, { useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { ListGroup } from "reactstrap";
import { getLocalMenu, handleMenu, userData } from "../utils/constants";

const Sidebar = () => {
  const history = useHistory();
  const [isLink, setIsLink] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);

  const handleChangeMenu = () => {
    handleMenu(menuOpen);
    setMenuOpen(getLocalMenu());
    getLocalMenu();
  };
  const logout = async (e) => {
    e.preventDefault();
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    history.push("/auth/login");
    window.location.reload();
  };
  return (
    <div className="left-wrap-container">
      <div className={`left-wrap `}>
        <div className="top-wrap">
          <div className="d-flex justify-content-sm-between user-name-container">
            <Link
              className="user-name-wrap "
              to={`/admin/${userData().id}/product`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="71.725"
                height="26.209"
                viewBox="0 0 71.725 26.209"
              >
                <g
                  id="Raggruppa_2267"
                  data-name="Raggruppa 2267"
                  transform="translate(-512.651 -403.779)"
                >
                  <path
                    id="Tracciato_3365"
                    data-name="Tracciato 3365"
                    d="M428.8,446.517a5.349,5.349,0,0,1,4.989,2.867l.193-2.591h3.225V460.74h-3.225l-.193-2.591a5.349,5.349,0,0,1-4.989,2.867,7.257,7.257,0,0,1,0-14.5Zm.579,11.3a4.052,4.052,0,1,0-4.107-4.052A3.989,3.989,0,0,0,429.382,457.818Z"
                    transform="translate(147.164 -37.804)"
                    fill="#091c7a"
                  />
                  <g
                    id="Raggruppa_2264"
                    data-name="Raggruppa 2264"
                    transform="translate(512.651 403.779)"
                  >
                    <ellipse
                      id="Ellisse_58"
                      data-name="Ellisse 58"
                      cx="2.539"
                      cy="2.539"
                      rx="2.539"
                      ry="2.539"
                      transform="translate(7.415 21.131)"
                      fill="#091c7a"
                    />
                    <path
                      id="Tracciato_3366"
                      data-name="Tracciato 3366"
                      d="M548.207,929.884a10.484,10.484,0,0,1-3.962-.73,9.57,9.57,0,0,1-3.157-2.055,9.3,9.3,0,0,1-2.092-3.12,10.1,10.1,0,0,1-.743-3.9,9.792,9.792,0,0,1,.755-3.863,9.538,9.538,0,0,1,2.117-3.12,9.784,9.784,0,0,1,3.17-2.08,10.191,10.191,0,0,1,3.912-.743,10.485,10.485,0,0,1,3.962.731,9.592,9.592,0,0,1,3.157,2.055,9.339,9.339,0,0,1,2.092,3.108,10.07,10.07,0,0,1,.743,3.912,9.8,9.8,0,0,1-.755,3.863,9.549,9.549,0,0,1-2.117,3.12,9.794,9.794,0,0,1-3.17,2.08A10.2,10.2,0,0,1,548.207,929.884Zm0-3.343a5.767,5.767,0,0,0,5.262-3.12,6.986,6.986,0,0,0,.78-3.343,6.914,6.914,0,0,0-.78-3.33,5.963,5.963,0,0,0-2.142-2.29,5.812,5.812,0,0,0-3.12-.842,5.767,5.767,0,0,0-5.262,3.12,7.5,7.5,0,0,0,0,6.661,5.935,5.935,0,0,0,2.142,2.3A5.807,5.807,0,0,0,548.207,926.541Z"
                      transform="translate(-538.253 -910.273)"
                      fill="#091c7a"
                    />
                  </g>
                  <path
                    id="Tracciato_3367"
                    data-name="Tracciato 3367"
                    d="M685.645,965.439v-13.9h3.632l.027,2.758a4.611,4.611,0,0,1,1.488-2.225,3.683,3.683,0,0,1,2.335-.778,4.966,4.966,0,0,1,.887.082,2.755,2.755,0,0,1,.724.218l-.328,3.577a6.066,6.066,0,0,0-.846-.246,4.193,4.193,0,0,0-.9-.109,2.848,2.848,0,0,0-2.4,1.106,5.153,5.153,0,0,0-.846,3.181v6.335Z"
                    transform="translate(-150.953 -542.486)"
                    fill="#091c7a"
                  />
                  <path
                    id="Tracciato_3368"
                    data-name="Tracciato 3368"
                    d="M754.649,964.518v-13.9h3.632l.054,1.993a4.025,4.025,0,0,1,1.652-1.788,5.053,5.053,0,0,1,2.581-.642,4.236,4.236,0,0,1,4.26,2.648,4.328,4.328,0,0,1,1.789-1.939,5.339,5.339,0,0,1,2.744-.71,4.6,4.6,0,0,1,3.713,1.516,6.608,6.608,0,0,1,1.283,4.382v8.437h-3.768v-7.727c0-2.166-.828-2.893-2.485-2.893a2.417,2.417,0,0,0-2.007.915,3.056,3.056,0,0,0-.723,2.224v7.482h-3.768v-7.755c0-2.148-.81-2.865-2.43-2.865a2.453,2.453,0,0,0-2.048.9,3.042,3.042,0,0,0-.71,2.21v7.509Z"
                    transform="translate(-208.905 -541.565)"
                    fill="#091c7a"
                  />
                </g>
              </svg>
            </Link>
            <span className="mobile__toggle d-md-none">
              <i className="bi bi-list pointer" onClick={handleChangeMenu}></i>
            </span>
          </div>

          <ListGroup>
            <NavLink
              className="list__link  "
              to={`/admin/${userData().id}/product`}
              isActive={(match, location) => {
                if (
                  location.pathname &&
                  location.pathname.includes("product")
                ) {
                  setIsLink("product");
                  return true;
                }
                return false;
              }}
            >
              <i
                className={`sidebar__icon bi bi-tag-fill ${
                  isLink ? "active__icon" : ""
                }`}
              ></i>{" "}
              Product
            </NavLink>
            <NavLink
              className="list__link "
              to={`/admin/${userData().id}/factory/factory-list`}
              isActive={(match, location) => {
                if (location.pathname && location.pathname.includes("factory"))
                  return true;
                return false;
              }}
            >
              <i className=" sidebar__icon bi bi-tags-fill"></i> Factories{" "}
            </NavLink>

            <NavLink
              className="list__link "
              to={`/admin/${userData().id}/galery/gallery-view`}
              isActive={(match, location) => {
                if (location.pathname && location.pathname.includes("gallery"))
                  return true;
                return false;
              }}
            >
              <i className=" sidebar__icon bi bi-nut-fill"></i> Gallery
            </NavLink>

            <NavLink
              activeClassName="selected"
              className="list__link "
              to={`/admin/${userData().id}/suppliers`}
              isActive={(match, location) => {
                if (
                  location.pathname &&
                  location.pathname.includes("suppliers")
                )
                  return true;
                return false;
              }}
            >
              <i className="bi bi-palette2 sidebar__icon"></i> Suppliers
            </NavLink>
            <NavLink
              className="list__link "
              to={`/admin/${userData().id}/analytics`}
              isActive={(match, location) => {
                if (
                  location.pathname &&
                  location.pathname.includes("analytics")
                )
                  return true;
                return false;
              }}
            >
              <i className=" sidebar__icon bi bi-star-fill"></i> Analytics
            </NavLink>

            <NavLink
              className="list__link "
              to={`/admin/${userData().id}/settings/general`}
              isActive={(match, location) => {
                if (location.pathname && location.pathname.includes("settings"))
                  return true;
                return false;
              }}
            >
              <i className=" sidebar__icon bi bi-credit-card-fill"></i> Settings
            </NavLink>
          </ListGroup>
        </div>

        <div className="bottom-wrap">
          <span className="px-4 text-danger pointer fw-bold" onClick={logout}>
            <i className=" sidebar__icon bi bi-power text-danger fw-bold"></i>{" "}
            Logout
          </span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
