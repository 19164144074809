import React from "react";
import { Col, Row } from "reactstrap";
import { Switch, Route, Link } from "react-router-dom";
import Routes from "../routers/authRouter";
import logo from "../assets/img/authLogo.svg";
import "./auth.scss";

const Register = () => {
  return (
    <div className="auth">
      <Row className="justify-space-between  align-items-center auth-row">
        <Col xl="7" md="5" sm="12" className="text-center">
          <Link to="#">
            <img src={logo} className="auth__logo" alt="auth__logo" />
          </Link>
        </Col>
        <Col xl="3" md="6" sm="12" className="bg-light auth-col my-3">
          <div className="auth-container">
            <Switch>
              {Routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  children={<route.component />}
                />
              ))}
            </Switch>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Register;
