import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
} from "reactstrap";

const EditPgOrderModal = ({ isOpen, toggle }) => {
  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        id="invite__user-modal"
        className="modal-dialog-centered"
      >
        <ModalHeader className="text-dark" toggle={toggle}>
          EDIT PAGE ORDER
        </ModalHeader>
        <ModalBody className="edit-page-order">
          <div className="order-wrap">
            <Row className="align-items-center h-100">
              <Col md={1} className="icon-col">
                <div className="order-icon">
                  <i class="bi bi-layout-three-columns"></i>
                </div>
              </Col>
              <Col md={11} className="details-col">
                <div className="order-details d-flex">
                  <div className="image">
                    <img src="" alt="" />
                  </div>
                  <p className="text-dark fw-bold font-13 m-0">
                    Something About the post
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-start">
          <Button className="btn-pry">EDIT</Button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditPgOrderModal;
