import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../../store/actions/uiAction";
import { Table } from "reactstrap";
const SettInvoices = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.handleMenuSettings(true));
    return () => {
      dispatch(actions.handleMenuSettings(false));
    };
  }, [dispatch]);
  return (
    <div id="settings-invoices">
      <div className="db-table ">
        <Table className="border table-responsive" hover>
          <thead>
            <tr>
              <th>DATE</th>
              <th>ORDER NUMBER</th>
              <th>PLANS</th>
              <th>AMOUNT</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {[1, 2, 3].map((o) => {
              return (
                <tr>
                  <td className="name">JUL 19, 2020</td>
                  <td className="count">RED DRESS X123</td>
                  <td className="count">Orma Starter</td>
                  <td className="count">29€</td>
                  <td className="">
                    <i className="bi bi-plus-circle"></i>
                    <i className="bi bi-plus-circle mx-2"></i>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default SettInvoices;
