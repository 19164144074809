import Register from "../auths/Register";
import CRegister from "../auths/CRegister";
import Login from "../auths/Login";
import ResetPwd from "../auths/ResetPwd";
import ForgotPwd from "../auths/ForgotPwd";

const Routes = [
  {
    path: "/auth/accept-invite",
    exact: true,
    component: Register,
  },
  {
    path: "/auth/company-register",
    exact: true,
    component: CRegister,
  },
  {
    path: "/auth/login",
    exact: true,
    component: Login,
  },
  {
    path: "/auth/forgot-password",
    exact: true,
    component: ForgotPwd,
  },
  {
    path: "/auth/reset-password",
    exact: true,
    component: ResetPwd,
  },
];
export default Routes;
