import { Get, Post, Put, Delete } from "../../apiServices/apiHandler.service";
import {
  INVITE_USER_LOADING,
  INVITE_USER_SUCCESS,
  INVITE_USER_ERROR,
  USER_SUCCESS,
  USER_ERROR,
  USER_LOADING,
  USER_EDIT_SUCCESS,
  USER_EDIT_ERROR,
  USER_EDIT_LOADING,
  COMPANY_DETAILS_SUCCESS,
  COMPANY_DETAILS_ERROR,
  COMPANY_DETAILS_LOADING,
  PENDING_USER_SUCCESS,
  PENDING_USER_ERROR,
  PENDING_USER_LOADING,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_ERROR,
  UPDATE_ROLE_LOADING,
  TEAM_SUCCESS,
  TEAM_ERROR,
  TEAM_LOADING,
  TEAM_EDIT_SUCCESS,
  TEAM_EDIT_ERROR,
  TEAM_EDIT_LOADING,
  STATUS_UPDATES_SUCCESS,
  STATUS_UPDATES_ERROR,
  STATUS_UPDATES_LOADING,
} from "./types";

export const inviteUser = (details) => {
  return async (dispatch) => {
    try {
      dispatch({ type: INVITE_USER_LOADING, payload: true });
      dispatch({ type: INVITE_USER_SUCCESS, payload: null });
      dispatch({ type: INVITE_USER_ERROR, payload: null });
      let response = null;
      response = await Post(`user-invite`, details);
      const { status, data } = response;
      if (status === 200) {
        response = data.data;
        dispatch({ type: INVITE_USER_SUCCESS, payload: response });
        dispatch({ type: INVITE_USER_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: INVITE_USER_LOADING, payload: false });
      dispatch({ type: INVITE_USER_ERROR, payload: error.response });
    }
  };
};

export const getUsers = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_LOADING, payload: true });
      dispatch({ type: USER_SUCCESS, payload: null });
      dispatch({ type: USER_ERROR, payload: null });

      const response = await Get(`user`);
      const { status, data } = response;
      if (status === 200) {
        dispatch({ type: USER_SUCCESS, payload: data });
        dispatch({ type: USER_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: USER_ERROR, payload: error });
      dispatch({ type: USER_LOADING, payload: false });
    }
  };
};


export const removeUser = (details) => {
  return async () => {
    try {
      const response = await Delete(`user/${details}`);
      const { status } = response;
      if (status === 200) {
        return response;
      }
    } catch (error) {
      return error;
    }
  };
};

export const getTeams = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: TEAM_LOADING, payload: true });
      dispatch({ type: TEAM_SUCCESS, payload: null });
      dispatch({ type: TEAM_ERROR, payload: null });

      const response = await Get(`team`);
      const { status, data } = response;
      if (status === 200) {
        dispatch({ type: TEAM_SUCCESS, payload: data });
        dispatch({ type: TEAM_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: TEAM_ERROR, payload: error });
      dispatch({ type: TEAM_LOADING, payload: false });
    }
  };
};

export const editUser = (details, id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_EDIT_LOADING, payload: true });
      dispatch({ type: USER_EDIT_SUCCESS, payload: null });
      dispatch({ type: USER_EDIT_ERROR, payload: null });
      const response = await Put(`user/${id}`, details);
      const { status, data } = response;
      if (status === 200) {
        dispatch({ type: USER_EDIT_SUCCESS, payload: data });
        dispatch({ type: USER_EDIT_LOADING, payload: false });
        return response;
      }
    } catch (error) {
      dispatch({ type: USER_EDIT_ERROR, payload: error });
      dispatch({ type: USER_EDIT_LOADING, payload: false });
    }
  };
};

export const getPendingUsers = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: PENDING_USER_LOADING, payload: true });
      dispatch({ type: PENDING_USER_SUCCESS, payload: null });
      dispatch({ type: PENDING_USER_ERROR, payload: null });

      const response = await Get(`user-invite`);
      const { status, data } = response;
      if (status === 200) {
        dispatch({ type: PENDING_USER_SUCCESS, payload: data });
        dispatch({ type: PENDING_USER_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: PENDING_USER_ERROR, payload: error });
      dispatch({ type: PENDING_USER_LOADING, payload: false });
    }
  };
};

export const removePendingUsers = (details) => {
  return async () => {
    try {
      const response = await Delete(`user-invite/${details}`);
      const { status } = response;
      if (status === 200) {
        return response;
      }
    } catch (error) {
       return error;
    }
  };
};


export const editTeam = (details, id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: TEAM_EDIT_LOADING, payload: true });
      dispatch({ type: TEAM_EDIT_SUCCESS, payload: null });
      dispatch({ type: TEAM_EDIT_ERROR, payload: null });

      const response = await Put(`team/${id}`, details);
      const { status, data } = response;
      if (status === 200) {
        dispatch({ type: TEAM_EDIT_SUCCESS, payload: data });
        dispatch({ type: TEAM_EDIT_LOADING, payload: false });
        return response;
      }
    } catch (error) {
      dispatch({ type: TEAM_EDIT_ERROR, payload: error });
      dispatch({ type: TEAM_EDIT_LOADING, payload: false });
    }
  };
};

export const removeTeam = (details) => {
  return async () => {
    try {
      const response = await Delete(`team/${details}`);
      const { status } = response;
      if (status === 200) {
        return response;
      }
    } catch (error) {
      return error;
    }
  };
};

export const addTeamUser = (details, id) => {
  return async () => {
    try {
      const response = await Post(`team/${id}/add-users`, details);
      const { status } = response;
      if (status === 200) {
        return response;
      }
    } catch (error) {
      return error;
    }
  };
};

export const inviteTeam = (details) => {
  return async () => {
    try {
      const response = await Post(`team`, details);
      const { status } = response;
      if (status === 201) {
        return response;
      }
    } catch (error) {
      return error;
    }
  };
};

export const updateStatus = (details) => {
  return async () => {
    try {
      const response = await Post(`media-status`, details);
      const { status } = response;
      if (status === 201) {
        return response;
      }
    } catch (error) {
      return error;
    }
  };
};

export const getStatusUpdates = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: STATUS_UPDATES_LOADING, payload: true });
      dispatch({ type: STATUS_UPDATES_SUCCESS, payload: null });
      dispatch({ type: STATUS_UPDATES_ERROR, payload: null });

      const response = await Get(`media-status`);
      const { status, data } = response;
      if (status === 200) {
        dispatch({ type: STATUS_UPDATES_SUCCESS, payload: data });
        dispatch({ type: STATUS_UPDATES_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: STATUS_UPDATES_ERROR, payload: error });
      dispatch({ type: STATUS_UPDATES_LOADING, payload: false });
    }
  };
};

export const removeStatus = (details) => {
  return async () => {
    try {
      const response = await Delete(`media-status/${details}`);
      const { status } = response;
      if (status === 200) {
        return response;
      }
    } catch (error) {
      return error;
    }
  };
};


export const getCompanyDetails = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: COMPANY_DETAILS_LOADING, payload: true });
      dispatch({ type: COMPANY_DETAILS_SUCCESS, payload: null });
      dispatch({ type: COMPANY_DETAILS_ERROR, payload: null });

      const response = await Get(`user/data`);
      const { status, data } = response;
      if (status === 200) {
        dispatch({ type: COMPANY_DETAILS_SUCCESS, payload: data });
        dispatch({ type: COMPANY_DETAILS_LOADING, payload: false });
      }
    } catch (error) {
      console.log("error", error);
      dispatch({ type: COMPANY_DETAILS_ERROR, payload: error });
      dispatch({ type: COMPANY_DETAILS_LOADING, payload: false });
    }
  };
};


export const updateUserRole = (details, id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_ROLE_LOADING, payload: true });
      dispatch({ type: UPDATE_ROLE_SUCCESS, payload: null });
      dispatch({ type: UPDATE_ROLE_ERROR, payload: null });

      const response = await Post(`user/${id}/roles`, details);
      const { status, data } = response;
      if (status === 200) {
        dispatch({ type: UPDATE_ROLE_SUCCESS, payload: data });
        dispatch({ type: UPDATE_ROLE_LOADING, payload: false });
        return response;
      }
    } catch (error) {
      dispatch({ type: UPDATE_ROLE_ERROR, payload: error });
      dispatch({ type: UPDATE_ROLE_LOADING, payload: false });
    }
  };
};
export const updateUserPicture = (details, id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_ROLE_LOADING, payload: true });
      dispatch({ type: UPDATE_ROLE_SUCCESS, payload: null });
      dispatch({ type: UPDATE_ROLE_ERROR, payload: null });

      const response = await Post(`user/${id}/profile-picture`, details);
      const { status, data } = response;
      if (status === 200) {
        dispatch({ type: UPDATE_ROLE_SUCCESS, payload: data });
        dispatch({ type: UPDATE_ROLE_LOADING, payload: false });
        return response;
      }
    } catch (error) {
      dispatch({ type: UPDATE_ROLE_ERROR, payload: error });
      dispatch({ type: UPDATE_ROLE_LOADING, payload: false });
    }
  };
};