import React from "react";
import { Button, Col, Form, FormGroup, Input, Label } from "reactstrap";

const TextPostAdd = () => {
  return (
    <>
      <Col xl="4" md="4" sm="12" className=" mid-col">
        <Form className="form-wrap">
          <Col className="mt-4 mb-3">
            <FormGroup>
              <Label className="info__label" for="name">
                <i className="bi bi-info-circle info__icon"></i> TEXT
              </Label>
              <Input
                type="textarea"
                name="desc"
                id="desc"
                className="info__input textarea"
                placeholder="Sweatshirt"
              />
            </FormGroup>
          </Col>
        </Form>
      </Col>

      <Col xl="6" md="6" sm="12" className="p-0 border-left">
        <h6 className="text-dark m-4 fw-normal">Preview</h6>
        <div className="text-desc-wrap">
          <h2 className="text-center">
            OUR MISSION IS TO BECOME THE MOST-LOVED LUXURY TRAVEL BRAND IN THE
            WORLD
          </h2>
        </div>

        <Col className="mt-3 border-top col p-3 text-right">
          <Button className="btn-pry">ADD TO PAGE</Button>
        </Col>
      </Col>
    </>
  );
};

export default TextPostAdd;
