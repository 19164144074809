export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_LOADING = "AUTH_LOADING";

export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS";
export const INVITE_USER_ERROR = "INVITE_USER_ERROR";
export const INVITE_USER_LOADING = "INVITE_USER_LOADING";

export const USER_SUCCESS = "USER_SUCCESS";
export const USER_ERROR = "USER_ERROR";
export const USER_LOADING = "USER_LOADING";

export const COMPANY_DETAILS_SUCCESS = "COMPANY_DETAILS_SUCCESS";
export const COMPANY_DETAILS_ERROR = "COMPANY_DETAILS_ERROR";
export const COMPANY_DETAILS_LOADING = "COMPANY_DETAILS_LOADING";

export const USER_EDIT_SUCCESS = "USER_EDIT_SUCCESS";
export const USER_EDIT_ERROR = "USER_EDIT_ERROR";
export const USER_EDIT_LOADING = "USER_EDIT_LOADING";


export const PENDING_USER_SUCCESS = "PENDING_USER_SUCCESS";
export const PENDING_USER_ERROR = "PENDING_USER_ERROR";
export const PENDING_USER_LOADING = "PENDING_USER_LOADING";

export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_ERROR = "UPDATE_ROLE_ERROR";
export const UPDATE_ROLE_LOADING = "UPDATE_ROLE_LOADING";

//TEAMS TYPES

export const INVITE_TEAM_SUCCESS = "INVITE_TEAM_SUCCESS";
export const INVITE_TEAM_ERROR = "INVITE_TEAM_ERROR";
export const INVITE_TEAM_LOADING = "INVITE_TEAM_LOADING";

export const TEAM_SUCCESS = "TEAM_SUCCESS";
export const TEAM_ERROR = "TEAM_ERROR";
export const TEAM_LOADING = "TEAM_LOADING";

export const TEAM_EDIT_SUCCESS = "TEAM_EDIT_SUCCESS";
export const TEAM_EDIT_ERROR = "TEAM_EDIT_ERROR";
export const TEAM_EDIT_LOADING = "TEAM_EDIT_LOADING";

export const STATUS_UPDATES_SUCCESS = "STATUS_UPDATES_SUCCESS";
export const STATUS_UPDATES_ERROR = "STATUS_UPDATES_ERROR";
export const STATUS_UPDATES_LOADING = "STATUS_UPDATES_LOADING";







export const SET_MENU_GALLERY = "SET_MENU_GALLERY";
export const SET_MENU_PRODUCT = "SET_MENU_PRODUCT";
export const SET_MENU_FACTORY = "SET_MENU_FACTORY";
export const SET_MENU_SETTINGS = "SET_MENU_SETTINGS";


