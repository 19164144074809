import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../../store/actions/uiAction";
import FactoryBanner from "./FactoryBanner";
import FactoryTab from "./FactoryTab";

const FactoryStory = () => {
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(actions.handleMenuFactory(true));
      return () => {
        dispatch(actions.handleMenuFactory(false));
      };
    }, [dispatch]);
  return (
    <>
      <FactoryBanner location={true} />
      <FactoryTab />
      <section id="product-story">
        <div className="contents my-5 mx-md-auto">
          <h4 className="title">
            MORE THAN 40 YEARS OF HAND MADE LUXURY CASHMERE APPAREL
          </h4>
          <p className="story">
            We are an Italian company specialized in the production of jeans and
            trousers located in Castiglioni of Arcevia in the province of Ancona
            in the Marche region. Founded in October 1978, its history shows
            commitment and dedication to work and growth. <br /> <br />
            The owners, Primo and Liana, were just 19 years old when they
            decided to “go it alone”, and in doing so they created a business
            that over the years has grown and has become established. The young
            couple actually developed their first products in their garage,
            guided by their innate entrepreneurial abilities and a tenacious
            propensity for new challenges and hard work. Their unrelenting
            desire to create a good product and to keep learning, led the young
            couple to grow and, over time, to acquire increasingly innovative
            machines and to surround themselves with capable staff. This has
            resulted in them producing for the most prestigious Denim brands
            both at a national and international level.
            <br /> <br />
            Confezioni Elegant’s machinery is now amongst the most
            technologically and commercially advanced on the market, and allows
            the company to guarantee the quality and competitiveness of their
            products. It has recently invested in a further expansion of the
            business surface area,(1500 square meters in total), so as to ensure
            a wide production capacity, (2000 pieces per day), as well as a
            healthy, new working environment for all employees.
            <br /> <br />
            The goal has always been to satisfy customer needs fully, ensuring
            reliability, offering high quality, competitive products, made ​​in
            a well-run, healthy, family owned environment, worthy of the
            “Entirely Made in Italy” label given to its products.
          </p>
        </div>

        {[1, 2, 3].map((o) => {
          return (
            <div className="banner my-4">
              <FactoryBanner />
            </div>
          );
        })}
      </section>
    </>
  );
};

export default FactoryStory;
