import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Col,
  Label,
  Form,
  Row,
} from "reactstrap";
import "./modal.scss";

const AddGoalModal = ({ isOpen, toggle }) => {
  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        id="goal-modal"
        className="modal-dialog-centered"
      >
        <ModalHeader className="text-dark" toggle={toggle}>
          ADD GOAL
        </ModalHeader>
        <ModalBody>
          <Form className="form-wrap">
            <Col className="mb-4">
              <FormGroup>
                <Label className="info__label pb-2" for="name">
                  SELECT A STATUS UPDATE YOU WANT FROM THE FACTORY
                </Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  className="info__input"
                  placeholder="Placeholder"
                />
              </FormGroup>
            </Col>
            <Row className="align-items-center">
              <Label className="info__label pb-4 pt-2" for="name">
                SELECT A STATUS UPDATE YOU WANT FROM THE FACTORY
              </Label>
              <Col className="mb-4" md="4">
                <FormGroup className="d-flex align-items-center">
                  <Label className="status__label text-dark" for="Pictures">
                    Pictures
                  </Label>
                  &nbsp;
                  <Input
                    type="number"
                    name="Pictures"
                    id="name"
                    className="media__input py-2"
                    placeholder="00"
                  />
                </FormGroup>
              </Col>
              <Col className="mb-4" md="4">
                <FormGroup className="d-flex align-items-center">
                  <Label className="status__label text-dark" for="Videos">
                    Videos
                  </Label>
                  &nbsp;
                  <Input
                    type="number"
                    name="Videos"
                    id="name"
                    className="media__input py-2"
                    placeholder="00"
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter className="justify-content-start">
          <Button className="btn-pry">ADD GOAL</Button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddGoalModal;
