import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../../store/actions/uiAction";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

const SettBilling = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.handleMenuSettings(true));
    return () => {
      dispatch(actions.handleMenuSettings(false));
    };
  }, [dispatch]);
  return (
    <div id="settings-billings">
      <div className="bill-wrap subscription-details border">
        <div className="bill-header border-bottom">
          <h6 className="font-15 text-dark m-0 pb-2 pt-3 sub__text">
            {" "}
            Subscription Details
          </h6>
        </div>
        <div className="bill-body py-4">
          <div className="sub-wrap d-flex justify-content-between align-items-center">
            <h6 className="font-15 text-dark">ORMA starter</h6>
            <div className="sub__cta">
              <div className="btn btn-sec">EDIT</div>
            </div>
          </div>
          <div className="sub-details text-dark font-12">
            Annual plan, paid monthly
          </div>
        </div>
      </div>

      <div className="bill-wrap subscription-form border my-5">
        <div className="bill-header border-bottom">
          <h6 className="font-15 text-dark m-0 pb-2 pt-3 sub__text">
            {" "}
            Dati pagamento
          </h6>
        </div>
        <div className="bill-body py-4">
          <Row className="justify-content-md-between">
            <Col md="4">
              <div className="payment-wrap">
                <FormGroup>
                  <Input
                    type="type"
                    name="type"
                    id="type"
                    className="write__status font-14"
                    placeholder="Pay your money now"
                  />
                </FormGroup>
                <p className="font-12 pay__desc py-2">
                  Modifica | Aggiungi Nuovo
                </p>
              </div>
            </Col>
            <Col md="4">
              <div className="price-desc">
                <h6 className="text-dark">29€ / Mese</h6>
                <p className="font-12 text-dark">
                  Il prossimo pagamento è previsto per il 19 Agosto 2021
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="bill-wrap shipping-form border my-5">
        <div className="bill-header border-bottom">
          <h6 className="font-15 text-dark m-0 pb-2 pt-3 sub__text">
            {" "}
            Dati pagamento
          </h6>
        </div>
        <div className="bill-body py-4">
          <Row className="justify-content-md-between">
            <Col md="6">
              <Form className="form-wrap">
                <Col className="mb-4">
                  <FormGroup>
                    <Label className="info__label" for="name">
                      Numero Carta Di Credito/Debito
                    </Label>
                    <Input
                      type="name"
                      name="name"
                      id="name"
                      className="info__input"
                      placeholder="Placeholder"
                    />
                  </FormGroup>
                </Col>
                <Col className="mb-4">
                  <FormGroup>
                    <Label className="info__label" for="name">
                      Name
                    </Label>
                    <Input
                      type="type"
                      name="type"
                      id="type"
                      className="info__input"
                      placeholder="Placeholder"
                    />
                  </FormGroup>
                </Col>

                <Row lg="2" md="2" sm="1" xs="1">
                  <Col className="mb-4">
                    <FormGroup>
                      <Label className="info__label" for="name">
                        Mese Di Scadenza
                      </Label>
                      <Input
                        type="code"
                        name="code"
                        id="code"
                        className="info__input"
                        placeholder="Placeholder"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="mb-4">
                    <FormGroup>
                      <Label className="info__label" for="name">
                        Anno Di Scadenza
                      </Label>
                      <Input
                        type="htag"
                        name="htag"
                        id="htag"
                        className="info__input"
                        placeholder="Placeholder"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row lg="2" md="2" sm="1" xs="1">
                  <Col className="mb-4">
                    <FormGroup>
                      <Label className="info__label" for="name">
                        Name
                      </Label>
                      <Input
                        type="code"
                        name="code"
                        id="code"
                        className="info__input"
                        placeholder="Placeholder"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="mb-4">
                    <FormGroup>
                      <Label className="info__label" for="name">
                        Surname
                      </Label>
                      <Input
                        type="htag"
                        name="htag"
                        id="htag"
                        className="info__input"
                        placeholder="Placeholder"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col md="6">
              <Form className="form-wrap">
                <Row lg="2" md="2" sm="1" xs="1">
                  <Col className="mb-4">
                    <FormGroup>
                      <Label className="info__label" for="name">
                        Codice Postale
                      </Label>
                      <Input
                        type="code"
                        name="code"
                        id="code"
                        className="info__input"
                        placeholder="Placeholder"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="mb-4">
                    <FormGroup>
                      <Label className="info__label" for="name">
                        Pease/Regione
                      </Label>
                      <Input
                        type="htag"
                        name="htag"
                        id="htag"
                        className="info__input"
                        placeholder="Placeholder"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Col className="mb-4">
                  <FormGroup>
                    <Label className="info__label" for="name">
                      Nome Azienda
                    </Label>
                    <Input
                      type="name"
                      name="name"
                      id="name"
                      className="info__input"
                      placeholder="Placeholder"
                    />
                  </FormGroup>
                </Col>
                <Col className="mb-4">
                  <FormGroup>
                    <Label className="info__label" for="name">
                      Partita Iva
                    </Label>
                    <Input
                      type="type"
                      name="type"
                      id="type"
                      className="info__input"
                      placeholder="Placeholder"
                    />
                  </FormGroup>
                </Col>
                <Col className="mb-4">
                  <FormGroup>
                    <Label className="info__label" for="name">
                      Partita Iva
                    </Label>
                    <Input
                      type="type"
                      name="type"
                      id="type"
                      className="info__input"
                      placeholder="Placeholder"
                    />
                  </FormGroup>
                </Col>
                <Col className="mb-4">
                  <Button className="btn btn__shipping">CAMBIA PIANO</Button>
                </Col>
              </Form>
            </Col>
          </Row>
        </div>
      </div>

      <div className="bill-wrap additional-options border my-5">
        <div className="bill-header border-bottom">
          <h6 className="font-15 text-dark m-0 pb-2 pt-3 sub__text">
            {" "}
            ADDITIONAL PAID OPTIONS
          </h6>
        </div>
        <div className="bill-body py-4 text-dark">
          <Row className="mb-3">
            <Col md="4">
              <h6 className="">Product experience page</h6>
              <p className="fw-normal font-12">1/1 page used.</p>
            </Col>
            <Col md="4">
              <h6>Teams</h6>
              <p className="fw-normal font-12">1/1 Team created.</p>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <h6>User</h6>
              <p className="fw-normal font-12">1/1 page used.</p>
            </Col>
            <Col md="4">
              <h6>API integrations</h6>
              <p className="fw-normal font-12">In use.</p>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default SettBilling;
