import React, { useEffect, useState } from "react";
import ProductTab from "./ProductTab";
import img from "../../assets/img/product-image.jpg";
import { Link } from "react-router-dom";
import * as actions from "../../../store/actions/uiAction";
import {
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Nav,
  NavItem,
} from "reactstrap";
import { useDispatch } from "react-redux";
import InviteUserOptModal from "../../modals/InviteUserOptModal";
const ProductInfo = () => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  // const [tooltipOpen, setTooltipOpen] = useState(false);

  // const toggleTip = () => setTooltipOpen(!tooltipOpen);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.handleMenuProduct(true));
    return () => {
      dispatch(actions.handleMenuProduct(false));
    };
  }, [dispatch]);
  return (
    <div>
      <ProductTab />
      <Nav className="border-bottom tab-bottom d-flex justify-content-md-around">
        <NavItem className="bottom-tab">
          <Link className="nav-link my-3 px-0" to="#" onClick={toggle}>
            {" "}
            <i className="bi bi-person-plus-fill"></i> INVITE USER
            <i className="bi bi-chevron-right"></i>
          </Link>
        </NavItem>
        <NavItem className="bottom-tab">
          <Link className="nav-link my-3" to="#">
            {" "}
            <i className="bi bi-people-fill"></i> INVITE TEAM
            <i className="bi bi-chevron-right"></i>
          </Link>
        </NavItem>
        <NavItem className="bottom-tab">
          <Link className="nav-link my-3" to="#">
            {" "}
            <i className="bi bi-person-plus-fill"></i> INVITE SUPPLIERS
            <i className="bi bi-chevron-right"></i>
          </Link>
        </NavItem>
        <NavItem className="bottom-tab">
          <Link className="nav-link my-3" to="#">
            {" "}
            <i className="bi bi-people-fill"></i> INVITE FACTORY
            <i className="bi bi-chevron-right"></i>
          </Link>
        </NavItem>
      </Nav>
      <div id="product-info">
        <div className="image-wrap mb-5">
          <Row>
            <Col xl="3" md="5" sm="4" xs="6">
              <div className="image">
                <img src={img} alt="" />
                <Link className="font-12">CHANGE IMAGE</Link>
              </div>
            </Col>
          </Row>
        </div>
        <div className="form-wrap">
          <Form>
            <Row lg="2" md="2" sm="1" xs="1">
              <Col>
                <FormGroup>
                  <Label className="info__label" for="name">
                    <i className="bi bi-info-circle info__icon"></i> PRODUCT
                    NAME
                  </Label>
                  <Input
                    type="name"
                    name="name"
                    id="name"
                    className="info__input"
                    placeholder="Placeholder"
                  />
                </FormGroup>
              </Col>
              <Col className="mb-4">
                <FormGroup>
                  <Label className="info__label" for="name">
                    <i className="bi bi-info-circle info__icon"></i> PRODUCT
                    TYPE
                  </Label>
                  <Input
                    type="type"
                    name="type"
                    id="type"
                    className="info__input"
                    placeholder="Placeholder"
                  />
                </FormGroup>
              </Col>
              <Col className="mb-4">
                <FormGroup>
                  <Label className="info__label" for="name">
                    <i className="bi bi-info-circle info__icon"></i> PRODUCT
                    CODE
                  </Label>
                  <Input
                    type="code"
                    name="code"
                    id="code"
                    className="info__input"
                    placeholder="Placeholder"
                  />
                </FormGroup>
              </Col>
              <Col className="mb-4">
                <FormGroup>
                  <Label className="info__label" for="name">
                    <i className="bi bi-info-circle info__icon"></i> PRODUCT
                    HASHTAG
                  </Label>
                  <Input
                    type="htag"
                    name="htag"
                    id="htag"
                    className="info__input"
                    placeholder="Placeholder"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Col className="mb-4">
              <FormGroup>
                <Label className="info__label" for="name">
                  <i className="bi bi-info-circle info__icon"></i> BRIEF
                  DESCRIPTION
                </Label>
                <Input
                  type="textarea"
                  name="desc"
                  id="desc"
                  className="info__input"
                  placeholder="Placeholder"
                />
              </FormGroup>
            </Col>
            <Row>
              <Col className="" xl="6" md="12">
                <FormGroup>
                  <Label className="info__label" for="name">
                    <i className="bi bi-info-circle info__icon"></i> DESIGN
                    LOCATION
                  </Label>
                  <Input
                    type="text"
                    name="location"
                    id="location"
                    className="info__input"
                    placeholder="Placeholder"
                  />
                </FormGroup>
              </Col>
              <Col className="mb-4 col-product__url" xl="6" md="12">
                <FormGroup>
                  <Label className="info__label" for="name">
                    <i className="bi bi-info-circle info__icon"></i> PRODUCT URL
                  </Label>
                  <div className="url-wrap">
                    <Row className="align-items-center">
                      <Col md="6" sm="12" xs="12">
                        <span className="url font-13">
                          http://localhost:3000/admin/1234/xyz
                        </span>
                      </Col>
                      <Col md="6" sm="12" xs="12">
                        <Input
                          type="name"
                          name="name"
                          id="name"
                          className="info__input"
                          placeholder="Placeholder"
                        />
                      </Col>
                    </Row>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Col className="mb-4">
              <FormGroup>
                <Label className="info__label" for="name">
                  <i className="bi bi-info-circle info__icon"></i> SHARE MESSAGE
                </Label>
                <Input
                  type="textarea"
                  name="name"
                  id="name"
                  className="info__input"
                  placeholder="Placeholder"
                />
              </FormGroup>
            </Col>
          </Form>
          <div className="barcode-wrap">
            <Row>
              <Col md="2">
                <span className="font-12 title">CUSTOMER PAGE</span>
                <div className="bar border">
                  <div className="code"></div>
                </div>
                <span className="font-12 download">DOWNLOAD</span>
              </Col>
              <Col md="2">
                <span className="font-12 title">WHOLESALER PAGE</span>
                <div className="bar border">
                  <div className="code"></div>
                </div>
                <span className="font-12 download">DOWNLOAD</span>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <InviteUserOptModal isOpen={modal} toggle={toggle} />
    </div>
  );
};

export default ProductInfo;
