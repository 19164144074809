import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../../store/actions/uiAction";
import "./settings.scss";
import { Col, Form, FormGroup, Label, Input } from "reactstrap";

const SettBrand = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.handleMenuSettings(true));
    return () => {
      dispatch(actions.handleMenuSettings(false));
    };
  }, [dispatch]);
  return (
    <div id="settings-brand">
      <Form className="form-wrap">
        <Col className="mb-4" md="7" sm="12">
          <FormGroup>
            <Label className="info__label" for="name">
              <i className="bi bi-info-circle info__icon"></i> BUTTON COLORS
            </Label>
            <Input
              type="type"
              name="type"
              id="type"
              className="info__input"
              placeholder="Placeholder"
            />
          </FormGroup>
        </Col>
        <Col className="mb-4" md="7" sm="12">
          <FormGroup>
            <Label className="info__label" for="name">
              <i className="bi bi-info-circle info__icon"></i> LINK COLORS
            </Label>
            <Input
              type="type"
              name="type"
              id="type"
              className="info__input"
              placeholder="Placeholder"
            />
          </FormGroup>
        </Col>
        <Col className="mb-4" md="7" sm="12">
          <FormGroup>
            <Label className="info__label" for="name">
              <i className="bi bi-info-circle info__icon"></i> CUSTOM STYLESHEET
            </Label>
            <Input
              type="type"
              name="type"
              id="type"
              className="info__input"
              placeholder="Placeholder"
            />
          </FormGroup>
        </Col>
        <Col className="mb-4" md="7" sm="12">
          <FormGroup>
            <Label className="info__label" for="name">
              <i className="bi bi-info-circle info__icon"></i> LOGO
            </Label>
            <Col md="2" className=" ">
              <div className="media-box " width="100">
                <div className="upload-product-text">
                  <span className=" font-15 fw-normal">
                    <i className="bi bi-plus-circle"></i> Add Logo
                  </span>
                </div>
              </div>
            </Col>
          </FormGroup>
        </Col>
      </Form>
    </div>
  );
};

export default SettBrand;
