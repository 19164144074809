import React, { useState } from "react";
import { Button } from "reactstrap";
import CreateFactoryModal from "../../modals/CreateFactory";

const FactoryHeader = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <div id="factory-header">
      <div className="btn-factory py-3 d-flex justify-content-between align-items-center">
        <span className="text-dark fw-bold">
          {" "}
          <i class="bi bi-chevron-left"></i> FACTORIES
        </span>
        <Button className="btn-pry" onClick={toggle}>
          ADD FACTORY
        </Button>
      </div>
      <CreateFactoryModal isOpen={modal} toggle={toggle}  />
    </div>
  );
};

export default FactoryHeader;
