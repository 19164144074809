import { useEffect, useState } from "react";
import { Button, ButtonGroup } from "reactstrap";
import * as actions from "../../../store/actions/uiAction";
import { Link } from "react-router-dom";
import { Nav, NavItem, Row, Col } from "reactstrap";
import timelineImg from "../../assets/img/timeline.jpg";
import FactoryBanner from "../factory/FactoryBanner";
import ProductTab from "./ProductTab";
import Switch from "react-switch";
import { useDispatch } from "react-redux";
import AddPostModal from "../../modals/AddPostModal";
import EditPgOrderModal from "../../modals/EditPgOrderModal";

const ProductExperience = () => {
  const [checked, setChecked] = useState(true);
  const [modalExp, setModalExp] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const toggleExp = () => setModalExp(!modalExp);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.handleMenuProduct(true));
    return () => {
      dispatch(actions.handleMenuProduct(false));
    };
  }, [dispatch]);

  return (
    <>
      <ProductTab />
      <section id="product-timeline">
        <div className="role-switch py-2">
          <div className="custom-control custom-switch d-flex align-items-center">
            <label className="d-flex align-items-center">
              <span className="switch__text font-12">Wholesaler</span>
              &nbsp;
              <Switch
                onChange={handleChange}
                checked={checked}
                uncheckedIcon={false}
                checkedIcon={false}
                className="react-switch"
                offColor="#e9ecef"
                onColor="#091C7A"
                onHandleColor="#ffffff"
              />
              &nbsp;
              <span className="switch__text font-12 text-dark fw-normal">
                Customer
              </span>
            </label>
            &nbsp;
          </div>
        </div>
        <FactoryBanner location={false} />
        <div className="hero-cta py-4 px-2 border-bottom">
          <Row>
            <Col xl="5" md="5" sm="6">
              <div className="description d-flex align-items-center">
                <span className="font-13 text-dark fw-bold f-name">
                  GREY SWEATSHIRT IN CASHMERE
                </span>
                <span className="font-13 fw-light l-name">
                  {" "}
                  GRIGIO MEDIO - 6771/100
                </span>
              </div>
            </Col>
            <Col xl="2" md="2" className="text-center">
              <div className="carousel-dots">...</div>
            </Col>
            <Col xl="5" md="5">
              <div className="cta-wrap d-flex align-items-center flex-lg-row flex-md-column justify-content-md-end">
                <span className="reaction__count font-12">724 REACTIONS </span>
                <span className="reaction__emojis ">
                  <i className="bi reactions bi-emoji-smile"></i>
                  <i className="bi reactions bi-emoji-neutral"></i>
                  <i className="bi reactions bi-emoji-angry"></i>
                  <i className="bi reactions bi-emoji-frown"></i>
                  <i className="bi reactions bi-emoji-wink"></i>
                </span>
                <Nav>
                  <NavItem className="">
                    <Link to="#" className="nav-link font-14 ">
                      Share
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link to="#" className="nav-link font-14">
                      Buy it now
                    </Link>
                  </NavItem>
                </Nav>
              </div>
            </Col>
          </Row>
        </div>
        <div className="btn my-4 timeline-cta w-100">
          <ButtonGroup>
            <Button
              className="btn-sec btn__add fw-normal"
              size="lg"
              onClick={toggleExp}
            >
              Add New Post
            </Button>
            <div className="border "></div>
            <Button
              className="btn-sec btn__edit fw-normal"
              size="lg"
              onClick={toggleEdit}
            >
              Edit Page Order
            </Button>
          </ButtonGroup>
        </div>
        <div className="timeline-story">
          <Row>
            <Col className="p-0 right-col">
              {[...Array(5).keys()].map((o) => {
                return (
                  <div className="timeline-wrap" key={o}>
                    <div className="timeline mx-auto">
                      <div className="timeline-img">
                        <img src={timelineImg} alt="timeline__image" />
                      </div>
                      <div className="details">
                        <div className="header">
                          <div className="left">
                            <h6 className="m-0 date">724 Reactions</h6>
                            <span className="reaction__emojis mx-2">
                              <i className="bi reactions bi-emoji-smile mx-1"></i>
                              <i className="bi reactions bi-emoji-neutral mx-1"></i>
                              <i className="bi reactions bi-emoji-angry mx-1"></i>
                              <i className="bi reactions bi-emoji-frown mx-1"></i>
                              <i className="bi reactions bi-emoji-wink mx-1"></i>
                            </span>
                          </div>
                          <div className="right h-100">
                            <Button className="btn-pry">SHARE</Button>
                          </div>
                        </div>
                        <div className="desc">
                          <p className="mt-3">
                            Meet Antonio. You probably already know him from
                            followin @CashmereAddict, but in case you don’t,
                            Antonio is the
                            <span className="read__more"> Read more…</span>{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Col>
          </Row>
        </div>
        <AddPostModal isOpen={modalExp} toggle={toggleExp} />
        <EditPgOrderModal isOpen={modalEdit} toggle={toggleEdit} />
      </section>
    </>
  );
};

export default ProductExperience;

