import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
} from "reactstrap";
import * as actions from "../../store/actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ValidateEmail } from "../utils/constants";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPassword, setIsPassword] = useState(false);
  const [errorExist, setErrorExist] = useState(false);
  let history = useHistory();
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const { auth } = selector;
  const handleTogglePassword = () => {
    setIsPassword(!isPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === "" || password === "" || password.length < 6) {
      setErrorExist(true);
    } else {
      const payload = {
        email,
        password,
      };
      setErrorExist(false);
      
      return await dispatch(actions.loginUser(payload));
    }
  };

  useEffect(() => {
    if (auth.authData && Object.keys(auth.authData).length) {
      history.push("/admin/tp4vt/product");
    }
    if (auth.authErr && auth.authErr.status === 403) {
      toast.error("Incorrect email / password! Kindly check and retry");
    }
    if (auth.authErr && auth.authErr.status !== 403) {
      toast.error("Something went wrong! Kindly retry");
    }
  }, [history, auth]);

  let pwdType = isPassword ? "text" : "password";
  let loading = auth && auth.authLoading;
  return (
    <Form className="form-wrap">
      <h6 className="text-dark py-4 fw-bold">LOGIN</h6>
      <Col className="mb-3">
        <FormGroup>
          <Label className="info__label" for="email">
            Email
          </Label>
          <Input
            type="email"
            name="email"
            id="email"
            className="info__input"
            placeholder="Enter email"
            value={email}
            required={true}
            onChange={(e) => setEmail(e.target.value)}
          />
          {email === "" && errorExist ? (
            <FormText className="font-12 text-danger">
              Email is required
            </FormText>
          ) : null}
          {email && !ValidateEmail(email) && (
            <FormText className="font-12 text-danger">
              Must be a valid email address
            </FormText>
          )}
        </FormGroup>
      </Col>
      <Col className="mb-3">
        <FormGroup>
          <Label
            className="info__label d-flex justify-content-between"
            for="password"
          >
            <span>Password</span>
            <Link
              to="/auth/forgot-password"
              className="text-light font-12 pointer"
            >
              Forgot your password?
            </Link>
          </Label>
          {isPassword ? (
            <i
              class="bi bi-eye-slash-fill font-13 right-input__icon"
              onClick={handleTogglePassword}
            ></i>
          ) : (
            <i
              class="bi bi-eye-fill font-13 right-input__icon"
              onClick={handleTogglePassword}
            ></i>
          )}
          <Input
            type={pwdType}
            name="password"
            id="password"
            className="info__input"
            placeholder="Password"
            value={password}
            required={true}
            onChange={(e) => setPassword(e.target.value)}
          />
          {password === "" && errorExist ? (
            <FormText className="font-12 text-danger">
              Password is required
            </FormText>
          ) : null}
          {password && errorExist && password.length < 6 && (
            <FormText className="font-12 text-danger">
              Password must be more than 6 characters
            </FormText>
          )}
        </FormGroup>
      </Col>
      <Col className="mb-3">
        <Button
          type="submit"
          className="btn d-flex align-items-center justify-content-center w-100 btn-pry text-white btnAuth__submit"
          onClick={handleSubmit}
        >
          {loading && (
            <>
              <div
                className="spinner-border text-white"
                style={{ height: "1rem", width: "1rem", color: "white" }}
                role="status"
              >
              </div>
              &nbsp;
            </>
          )}
          LOG IN WITH ORMA
        </Button>
      </Col>
      {/* <Col className="mb-4">
        <Link
          to="/auth/reset-password"
          className="btn d-block w-100 btn-pry text-white"
        >
          LOGIN WITH GOOGLE
        </Link>
      </Col> */}
      <p className="font-12 text-center text-light">
        Don't have an account yet?{" "}
        <Link to="/auth/company-register" className="text-dark">
          Register
        </Link>
      </p>
    </Form>
  );
};

export default Login;
