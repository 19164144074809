import React from "react";
// import { Link } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, Label } from "reactstrap";

const MapPostAdd = () => {
  return (
    <>
      <Col xl="4" md="4" sm="12" className="mid-col">
        <Form className="form-wrap">
          <Col className=" mt-4 mb-3">
            <FormGroup>
              <Label className="info__label" for="name">
                <i className="bi bi-info-circle info__icon"></i> SELECT LOCATION
              </Label>
              <Input
                type="text"
                name="htag"
                id="htag"
                className="info__input"
                placeholder="Search"
              />
            </FormGroup>
          </Col>
          <Col className=" mb-3">
            <FormGroup>
              <Label className="info__label" for="name">
                <i className="bi bi-info-circle info__icon"></i> COMMENT
              </Label>
              <Input
                type="textarea"
                name="desc"
                id="desc"
                className="info__input textarea"
                placeholder="Sweatshirt"
              />
            </FormGroup>
          </Col>
        </Form>
      </Col>

      <Col xl="6" md="6" sm="12" className="p-0 border-left">
        <h6 className="text-dark m-4 fw-normal">Preview</h6>
        <div className="upload-col">
          <div className="upload-wrap d-flex flex-direction-column justify-content-center h-100">
            <div className="upload-box  d-flex justify-content-center flex-column align-items-center">
              <span className="text-grey fw-bold d-flex">
                {" "}
                <i class="bi bi-plus-circle media__icon"></i> &nbsp; upload or
                drag a file{" "}
              </span>
              <hr className="w-100 " />
              <span className="text-grey fw-bold d-flex">
                <i class="bi bi-card-image media__icon"></i> &nbsp; Select from
                the gallery
              </span>
            </div>
          </div>
        </div>
        <Col className="mt-3 border-top col p-3 text-right">
          <Button className="btn-pry">ADD TO PAGE</Button>
        </Col>
      </Col>
    </>
  );
};

export default MapPostAdd;
