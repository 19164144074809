import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
} from "reactstrap";

const CreateProductModal = ({ isOpen, toggle }) => {
  return (
    <div id="product-add-media-modal">
      <Modal
        className="modal-dialog-centered modal-md custom-create-prod-modal"
        isOpen={isOpen}
        toggle={toggle}
      >
        <ModalHeader className="border-0 pb-0" toggle={toggle}>
          <span className="font-13 text-white pointer" onClick={toggle}>
            <i class="bi bi-chevron-left media__icon"> </i>
            UPLOAD A PRODUCT
          </span>
        </ModalHeader>
        <ModalBody className="product-add-media-modal-body">
          <Form className="form-wrap h-100">
            <Row className="h-100">
              <Col md={6}>
                <div className="upload-wrap d-flex flex-direction-column justify-content-center h-100">
                  <div className="upload-box  d-flex justify-content-center flex-column align-items-center">
                    <span className=" fw-bold d-flex">
                      <i class="bi bi-plus-circle media__icon"></i> &nbsp;
                      Upload Product Image
                    </span>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <Col className="mb-2">
                  <FormGroup>
                    <Input
                      type="select"
                      className="text-dark fw-bold info__input"
                      name="status"
                    >
                      <option>SELECT A STATUS</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col className="mb-2">
                  <FormGroup>
                    <Input
                      type="text"
                      className="text-dark fw-bold info__input"
                      name="status"
                      placeholder="Product name"
                    ></Input>
                  </FormGroup>
                </Col>
                <Button className="btn-pry">CREATE A NEW PRODUCT</Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CreateProductModal;
