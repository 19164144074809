import React from "react";
import { Col, Form, Input, Row } from "reactstrap";

const ProductFilter = () => {
  return (
    <Row
      md="2"
      sm="2"
      xs="1"
      className="justify-content-md-between align-items-center"
    >
      <Col md="5">
        <Form>
          <Input
            type="text"
            name="search"
            id="search"
            className="search py-2"
            placeholder="Search"
          />
        </Form>
      </Col>
      <Col md="2">
        <span className="filter__text">
          Filter by <i className="bi bi-chevron-down"></i>
        </span>
      </Col>
    </Row>
  );
};

export default ProductFilter;
