import React from "react";
import { Button, Col, Row } from "reactstrap";
import "./products.scss";
import img1 from "../../assets/img/product-image.jpg";
import { Link } from "react-router-dom";
import ProductFilter from "./ProductFilter";
import { userData } from "../../utils/constants";

const Products = () => {
  return (
    <section id="db-product">
      <div className="wrap">
        <ProductFilter />
        <div className="product-desc">
          <Row>
            <Col xl="4" md="12" sm="12" xs="12" className="mx-md-auto">
              <div className="image">
                <img src={img1} alt="Product__logo" />
              </div>
            </Col>
            <Col xl="8" md="12" sm="12" xs="12" className="mx-auto my-sm-4">
              <Link to={`/admin/${userData().id}/product/product-list`}>
                <div className="contents">
                  <h5>
                    More Than 40 Years of Hand Made Luxury Cashmere Apparel
                  </h5>
                  <p className="pry-color">
                    We are an Italian company specialized in the production of
                    jeans and trousers located in Castiglioni of Arcevia in the
                    province of Ancona in the Marche region.
                  </p>
                  <div className="btn-wrap">
                    <Button className="btn-pry">SAVE AS DRAFT</Button>
                  </div>
                </div>
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default Products;
