import React from "react";
import { Col, Row } from "reactstrap";
import Sidebar from "./Sidebar";
import "./dashboard.scss";
import "../assets/css/utilities.scss";
import DashboardRoute from "./DashboardRoute";
import { useSelector } from "react-redux";
import SettingsSidebar from "./settings/SettingsSidebar";

const Dashboard = () => {
  const select = useSelector((state) => state);

  let { ui } = select;
  return (
    <div className="dashboard">
      <Row>
        {ui && ui.isMenuSettings ? (
          <>
            <Col md="2" className="sidebar">
              <Sidebar />
            </Col>
            <Col md="2" className="settings-sidebar">
              <SettingsSidebar />
            </Col>
            <Col md="8" className="right-col p">
              <DashboardRoute />
            </Col>
          </>
        ) : (
          <>
            <Col md="2" className="sidebar">
              <Sidebar />
            </Col>
            <Col md="10" className="right-col p">
              <DashboardRoute />
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default Dashboard;
