import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions";
import "./settings.scss";
import { Col, FormGroup, Input, Button, FormText } from "reactstrap";
import { toast } from "react-toastify";
import { showAlert } from "../../utils/constants";

const SettUpdates = () => {
  const [data, setData] = useState([]);
  const [texts, setTexts] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [errorExist, setErrorExist] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const { settings } = selector;
  const { updatedStatus, updatedStatusErr, updatedStatusLoading } = settings;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (texts === "" || texts.length < 3) {
      setErrorExist(true);
    } else {
      const payload = {
        status: texts,
      };
      setErrorExist(false);
      setIsLoading(true);
      return await dispatch(actions.updateStatus(payload)).then((res) => {
        let { data, status } = res;
        if (status === 201 && data) {
          dispatch(actions.getStatusUpdates());
          toast.success("Status Sucessfully Added");
          resetForm();
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error("Something went wrong! Kindly retry");
        }
      });
    }
  };

  const removeStatus = ({ target: { id } }) => {
    showAlert(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning"
    ).then((result) => {
      if (result.isConfirmed) {
    
        setDeleteId(id);
        setIsDeleting(true);
        dispatch(actions.removeStatus(id)).then((res) => {
          if (res.status === 200) {
            dispatch(actions.getStatusUpdates());
            setIsDeleting(false);
            toast.success("Status deleted successfully");
          } else {
            toast.error("Something went wrong");
            setIsDeleting(false);
          }
        });
      }
    });
  };

  const resetForm = () => {
    setTexts("");
  };

  useEffect(() => {
    dispatch(actions.getStatusUpdates());
    dispatch(actions.handleMenuSettings(true));
    return () => {
      dispatch(actions.handleMenuSettings(false));
    };
  }, [dispatch]);

  useEffect(() => {
   

    if (
      updatedStatus &&
      Object.keys(updatedStatus).length 
    ) {
      setData(updatedStatus.data);
    }
    if (!updatedStatus && updatedStatusErr && !updatedStatusLoading) {
      console.log("Something went wrong");
    }
  }, [updatedStatus ,
      updatedStatusErr ,
      updatedStatusLoading,
  ]);

  return (
    <div id="settings-status__updates">
      <div className="default-status">
        <h6 className="font-14 text-dark"> DEFAULT STATUS</h6>
        <Col xl="7" md="10" sm="12" className="mt-3">
          <div className="tags font-12">
            <span className="tag active">Trimming</span>
            <span className="tag">Washing</span>
            <span className="tag">Dying</span>
            <span className="tag ">Sewing</span>
            <span className="tag">Pattern Making</span>
            <span className="tag">Sourcing</span>
          </div>
        </Col>
      </div>
      <div className="default-status">
        <Col xl="7" md="12" sm="12" className="mt-5">
          <h6 className="font-14 text-dark">
            {" "}
            <i className="bi bi-info-circle info__icon"></i> COSTUM STATUSES
          </h6>

          <div className="status-add d-flex font-14">
            <FormGroup>
              <Input
                type="type"
                name="type"
                id="type"
                className="write__status font-14"
                placeholder="Placeholder"
                value={texts}
                onChange={({ target }) => setTexts(target.value)}
              />
            </FormGroup>
            <Button
              className="btn-pry mx-md-3 add__status font-14"
              disabled={isLoading && isLoading}
              onClick={handleSubmit}
            >
              {isLoading ? (
                <>
                  <div
                    className="spinner-border text-white"
                    style={{ height: "1rem", width: "1rem", color: "white" }}
                    role="status"
                  ></div>{" "}
                  ADDING STATUS...
                </>
              ) : (
                "ADD STATUS"
              )}
            </Button>
          </div>
          <div className="error-block mb-3">
            {texts === "" && errorExist ? (
              <FormText className="font-12 text-danger">
                Email is required
              </FormText>
            ) : null}
            {texts && texts.length < 3 && (
              <FormText className="font-12 text-danger">
                Status must contain 3 characters or more
              </FormText>
            )}
          </div>

          <div className="tags font-12">

            {data &&
              data.length &&
              !settings.updatedStatusLoading
              ?
              data.map((result) => {
                
                let { id, status } = result;
             
                return (
                  <span className="tag border-0 " key={id}>
                    {status}{" "} {deleteId && +deleteId === +id && isDeleting ? (
                      <span className="text-danger fw-bolder font-15">&nbsp;   <div
                      class="spinner-border text-danger delete__loader"
                      role="status"
                    ></div></span>
                    ) : (
                      <i className={`bi bi-x fw-bold ${ isDeleting ? 'd-none': 'pointer'}`} id={id} onClick={removeStatus}></i>
                    )}
                  </span>
                );
              }):null}
          </div>
          <div className="d-flex ">
            {settings.updatedStatusLoading ? (
              <div
                class="spinner-border text-success data__loader"
                role="status"
              ></div>
            ) : !data.length ? (
              "No data to show"
            ) : null}
          </div>
        </Col>
      </div>
    </div>
  );
};

export default SettUpdates;
