import React from "react";
import { Modal, ModalHeader, ModalBody, Row, Col, Form } from "reactstrap";
import img from "../assets/img/tailor.jpg";
import photo from "../assets/img/user-photo.jpg";
const ProdDetailModal = ({ isOpen, toggle }) => {
  return (
    <div id="product-add-media-modal">
      <Modal
        className="modal-dialog-centered modal-lg prod-detail-modal"
        isOpen={isOpen}
        toggle={toggle}
      >
        <ModalHeader className="border-0 pb-0" toggle={toggle}></ModalHeader>
        <ModalBody className="prod-details-body">
          <Form className="form-wrap h-100">
            <Row className="h-100">
              <Col md={6} className="img-col">
                <div className="img-wrap d-flex flex-direction-column justify-content-center h-100">
                  <img src={img} alt="Product details" />
                </div>
              </Col>
              <Col md={6} className="desc-col">
                <div className="prod-details">
                  <div className="header border-bottom">
                    <h6 className="text-dark pt-4 font-15">
                      MAGLIA IN CASHMERE{" "}
                      <span className="sub__name">
                        | GRIGIO MEDIO - 6771/100
                      </span>
                    </h6>
                  </div>
                  <div className="content">
                    <div className="user-details my-3">
                      <Row className="align-items-center">
                        <Col md={6}>
                          <div className="user-profile d-flex align-items-center border-right">
                            <div className="photo border">
                              <img src={photo} alt="User__photo" />
                            </div>
                            &nbsp;
                            <span className="font-14 text-dark fw-bold">
                              James Mcavoy
                            </span>
                          </div>
                        </Col>
                        <Col>
                          <div className="tags">
                            {[1, 2, 3, 4].map((o) => (
                              <span className="tag">Trimming</span>
                            ))}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <p className="desc">
                      Following the success of the original series,
                      Hanna-Barbera and i ts successor Warner Bros. Animation
                      have produced numerous follow-up.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ProdDetailModal;
