import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { ListGroup } from "reactstrap";
import { userData } from "../../utils/constants";
const SettingsSidebar = () => {
  let id = userData().id;
  const [, setIsLink] = useState("");
  // const [menuOpen, setMenuOpen] = useState(false);
  // const handleChangeMenu = () => {
  //   handleMenu(menuOpen);
  //   setMenuOpen(getLocalMenu());
  //   getLocalMenu();
  // };
  return (
    <div className={`settings-left-wrap `}>
      <div className="top-wrap">
        <h6 className="title company__name">Company Settings</h6>
        <ListGroup className="mt-4">
          <NavLink
            className="list__link  "
            to={`/admin/${id}/settings/general`}
            isActive={(match, location) => {
              if (location.pathname && location.pathname.includes("general")) {
                setIsLink("general");
                return true;
              }
              return false;
            }}
          >
            General
          </NavLink>
          <NavLink
            className="list__link  "
            to={`/admin/${id}/settings/users`}
            isActive={(match, location) => {
              if (location.pathname && location.pathname.includes("users")) {
                setIsLink("users");
                return true;
              }
              return false;
            }}
          >
            Users
          </NavLink>
          <NavLink
            className="list__link  "
            to={`/admin/${id}/settings/teams`}
            isActive={(match, location) => {
              if (location.pathname && location.pathname.includes("teams")) {
                setIsLink("teams");
                return true;
              }
              return false;
            }}
          >
            Teams
          </NavLink>
          <NavLink
            className="list__link  "
            to={`/admin/${id}/settings/brand`}
            isActive={(match, location) => {
              if (location.pathname && location.pathname.includes("brand")) {
                setIsLink("brand");
                return true;
              }
              return false;
            }}
          >
            Branding
          </NavLink>
          <NavLink
            className="list__link  "
            to={`/admin/${id}/settings/status-update`}
            isActive={(match, location) => {
              if (location.pathname && location.pathname.includes("status")) {
                setIsLink("status");
                return true;
              }
              return false;
            }}
          >
            Status Updates
          </NavLink>
        </ListGroup>
      </div>

      <div className="mid-wrap">
        <h6 className="title">Account & Billing</h6>
        <ListGroup>
          <NavLink
            className="list__link  "
            to={`/admin/${id}/settings/billing`}
            isActive={(match, location) => {
              if (location.pathname && location.pathname.includes("billing")) {
                setIsLink("billing");
                return true;
              }
              return false;
            }}
          >
            Billing
          </NavLink>
          <NavLink
            className="list__link  "
            to={`/admin/${id}/settings/invoices`}
            isActive={(match, location) => {
              if (location.pathname && location.pathname.includes("invoices")) {
                setIsLink("invoices");
                return true;
              }
              return false;
            }}
          >
            Invoices
          </NavLink>
        </ListGroup>
      </div>

      <div className="bottom-wrap">
        <h6 className="title">My Settings</h6>
        <ListGroup>
          <NavLink
            className="list__link  "
            to={`/admin/${id}/settings/info`}
            isActive={(match, location) => {
              if (location.pathname && location.pathname.includes("info")) {
                setIsLink("info");
                return true;
              }
              return false;
            }}
          >
            Basic Info
          </NavLink>
          <NavLink
            className="list__link  "
            to={`/admin/${id}/settings/security`}
            isActive={(match, location) => {
              if (location.pathname && location.pathname.includes("security")) {
                setIsLink("security");
                return true;
              }
              return false;
            }}
          >
            Security
          </NavLink>
        </ListGroup>
      </div>
    </div>
  );
};;;

export default SettingsSidebar;
