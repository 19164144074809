import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../../store/actions/uiAction";
import "./settings.scss";
import { Col, Form, FormGroup, Label, Input } from "reactstrap";

const SettGeneral = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.handleMenuSettings(true));
    return () => {
      dispatch(actions.handleMenuSettings(false));
    };
  }, [dispatch]);
  return (
    <div id="settings-general">
      <Form className="form-wrap">
        <Col className="mb-4" md="7">
          <FormGroup>
            <Label className="info__label" for="name">
              <i className="bi bi-info-circle info__icon"></i> BRAND NAME
            </Label>
            <Input
              type="type"
              name="type"
              id="type"
              className="info__input"
              placeholder="Placeholder"
            />
          </FormGroup>
        </Col>
        <Col className="mb-4" md="7">
          <FormGroup>
            <Label className="info__label" for="name">
              <i className="bi bi-info-circle info__icon"></i> BRAND WEBSITE URL
            </Label>
            <Input
              type="type"
              name="type"
              id="type"
              className="info__input"
              placeholder="Placeholder"
            />
          </FormGroup>
        </Col>
        <Col className="mb-4" md="7">
          <FormGroup>
            <Label className="info__label" for="name">
              <i className="bi bi-info-circle info__icon"></i> COMPANY ADDRES
            </Label>
            <Input
              type="type"
              name="type"
              id="type"
              className="info__input"
              placeholder="Placeholder"
            />
          </FormGroup>
        </Col>
      </Form>
    </div>
  );
};

export default SettGeneral;
