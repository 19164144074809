import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Col,
  Label,
  Form,
} from "reactstrap";

const CreateFactoryModal = ({ isOpen, toggle }) => {
  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        id="invite__user-modal"
        className="modal-dialog-centered"
      >
        <ModalHeader className="text-dark" toggle={toggle}>
          CREATE A NEW FACTORY ACCOUNT
        </ModalHeader>
        <ModalBody>
          <Form className="form-wrap">
            <Col className="my-4">
              <FormGroup>
                <Label className="info__label pb-2" for="name">
                  FACTORY NAME
                </Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  className="info__input"
                  placeholder="Name"
                />
              </FormGroup>
            </Col>
            <Col className="mb-4 mt-4">
              <FormGroup>
                <Label className="info__label pb-2" for="location">
                  FACTORY LOCATION
                </Label>
                <Input
                  type="text"
                  name="location"
                  id="location"
                  className="info__input"
                  placeholder="Location"
                />
              </FormGroup>
            </Col>
          </Form>
        </ModalBody>
        <ModalFooter className="justify-content-start">
          <Button className="btn-pry"> CREATE</Button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CreateFactoryModal;
