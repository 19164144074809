import React from "react";
import { Link } from "react-router-dom";
import { Button, Row, Col, Form, FormGroup, Input, Label } from "reactstrap";
import img1 from "../../../assets/img/product-image.jpg";

const VideoPostAdd = () => {
  return (
    <>
      <Col xl="4" md="4" sm="12" className=" mid-col">
        <Form className="form-wrap">
          <Col className="mt-4 mb-3">
            <FormGroup>
              <Label className="info__label" for="name">
                <i className="bi bi-info-circle info__icon"></i> COMMENT
              </Label>
              <Input
                type="textarea"
                name="desc"
                id="desc"
                className="info__input textarea"
                placeholder="Sweatshirt"
              />
            </FormGroup>
          </Col>
          <Col className="mb-3">
            <FormGroup>
              <Label className="info__label" for="name">
                <i className="bi bi-info-circle info__icon"></i> GALLERY IMAGES
              </Label>
              <Input
                type="htag"
                name="htag"
                id="htag"
                className="info__input"
                placeholder="Search"
              />
            </FormGroup>
          </Col>

          <div className="my-4">
            <Row>
              <Col className="my-2 cont" md="6">
                <div className="media-box">
                  <div className="upload-product-text">
                    <span className="pointer font-14">
                      <i className="bi bi-plus-circle"></i> Add Media
                    </span>
                  </div>
                </div>
              </Col>
              {[...Array(10).keys()].map((o) => {
                return (
                  <Col className="my-2 cont" md="6" key={o}>
                    <div className="box">
                      <Link to="#">
                        <div className="image">
                          <img src={img1} alt="gallery" />
                        </div>
                      </Link>
                      <p className="m-0 font-12 fw-light text-center">
                        imagename.jpeg
                      </p>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </Form>
      </Col>

      <Col xl="6" md="6" sm="12" className="border-left p-0">
        <div className="upload-col">
          <div className="upload-wrap d-flex flex-direction-column justify-content-center h-100">
            <div className="upload-box  d-flex justify-content-center flex-column align-items-center">
              <span className="text-grey fw-bold d-flex">
                {" "}
                <i class="bi bi-plus-circle media__icon"></i> &nbsp; upload or
                drag a file{" "}
              </span>
              <hr className="w-100 " />
              <span className="text-grey fw-bold d-flex">
                <i class="bi bi-card-image media__icon"></i> &nbsp; Select from
                the gallery
              </span>
            </div>
          </div>
        </div>
        <Col className="mt-3 border-top col p-3 text-right">
          <Button className="btn-pry">ADD TO PAGE</Button>
        </Col>
      </Col>
    </>
  );
};

export default VideoPostAdd;
