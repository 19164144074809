import React, { useEffect, useState } from "react";
import * as actions from "../../../store/actions/uiAction";
import { Button, Col, Row, Table } from "reactstrap";
import ProductTab from "./ProductTab";
import Switch from "react-switch";
import { useDispatch } from "react-redux";
import AddGoalMod from "../../modals/AddGoalModal";



const ProductConfig = () => {
  const [checked, setChecked] = useState(false);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.handleMenuProduct(true));
    return () => {
      dispatch(actions.handleMenuProduct(false));
    };
  }, [dispatch]);
  return (
    <div id="product-config">
      <ProductTab />
      <div className="team-users overflow-hidden">
        {[1, 2, 3].map((o) => {
          return (
            <Row
              className="mx-xs-auto border-bottom py-4 config-role mb-xs-2"
              key={o}
            >
              <Col xl="4" md="6" sm="6" className="my-xs-3">
                <div className="config-left d-flex align-items-center">
                  <span className="font-13 text-dark fw-bold">
                    ACTIVATE CUSTOMER PAGE
                  </span>
                  &nbsp; &nbsp;
                  <div className="custom-control custom-switch d-flex align-items-center config__switch">
                    <label className="d-flex">
                      <Switch
                        onChange={handleChange}
                        checked={checked}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        className="react-switch"
                        offColor="#e9ecef"
                        onColor="#0095FF"
                        onHandleColor="#ffffff"
                      />
                    </label>
                    &nbsp;
                    <span className="switch__text">
                      <i className=" bi bi-info-circle config__icon"></i>
                    </span>
                  </div>
                </div>
              </Col>
              <Col xl="8" md="6" sm="6">
                <div className="config-right d-flex align-items-center">
                  <span className="font-13 text-dark fw-bold">
                    ACTIVATE CUSTOMER PAGE
                  </span>
                  &nbsp; &nbsp;
                  <div className="custom-control custom-switch d-flex align-items-center config__switch">
                    <label className="d-flex">
                      <Switch
                        onChange={handleChange}
                        checked={checked}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        className="react-switch"
                        offColor="#e9ecef"
                        onColor="#0095FF"
                        onHandleColor="#ffffff"
                      />
                    </label>
                    &nbsp;
                    <span className="switch__text">
                      <i className=" bi bi-info-circle config__icon"></i>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          );
        })}
      </div>

      <div className="role-details">
        <div className="suppliers mt-5">
          <div className="header mb-3 d-flex justify-content-between align-items-center">
            <h6 className="m-0 title">MEDIA GOALS</h6>

            <div className="suppliers-btn-wrap">
              <Button className="btn btn-sec" onClick={toggle}>
                {" "}
                ADD GOAL
              </Button>
            </div>
          </div>
          <div className="db-table">
            <Table className="border table-responsive" hover>
              <tbody>
                {[1, 2, 3].map((O) => {
                  return (
                    <tr>
                      <td className="name">JAMES MACOY</td>
                      <td className="name">5 PICTURES, 1 VIDEO</td>
                      <td className="cta text-right">Edit</td>
                      <td className="cta text-right">Remove</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <AddGoalMod isOpen={modal} toggle={toggle} />
    </div>
  );
};

export default ProductConfig;
