import React from "react";
import { Table } from "reactstrap";
const Analytics = () => {
  return (
    <div id="settings-invoices">
      <div className="db-table ">
        <Table className="border table-responsive" hover>
          <thead>
            <tr>
              <th>PRODUCT</th>
              <th className="text-center">PAGE VIEWS</th>
              <th className="text-center">REACTIONS</th>
              <th className="text-center">GLOBAL FOOTPRINT VIEWS</th>
              <th className="text-center">SOCIAL MEDIA SHARES</th>
            </tr>
          </thead>
          <tbody>
            {[1, 2, 3].map((o) => {
              return (
                <tr>
                  <td className="name">RED DRESS X123</td>
                  <td className="count text-center">300</td>
                  <td className="count text-center">300</td>
                  <td className="count text-center">300</td>
                  <td className="count text-center">300</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Analytics;
