import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
} from "reactstrap";
import ImagePostAdd from "../dashboard/product/addPost/ImagePostAdd";
import MapPostAdd from "../dashboard/product/addPost/MapPostAdd";
import PostSidebar from "../dashboard/product/addPost/PostSidebar";
import VideoPostAdd from "../dashboard/product/addPost/VideoPostAdd";
import TimelinePostAdd from "../dashboard/product/addPost/TimelinePostAdd";
import MilestonePostAdd from "../dashboard/product/addPost/MilestonePostAdd";
import '../dashboard/product/addPost/addPost.scss'
import TextPostAdd from "../dashboard/product/addPost/TextPostAdd";

const AddPostModal = ({ isOpen, toggle }) => {
  const [navState, setNavState] = useState('image')
 const getNavState =(state) =>{
  setNavState( state)
 }
  return (
    <div>
      <Modal
        className="modal-dialog-centered modal-lg add-post-modal modal-fullscreen-md"
        isOpen={isOpen}
        toggle={toggle}
      >
        <ModalHeader className="border-0 pb-0" toggle={toggle}></ModalHeader>
        <ModalBody className="">
          <div className="widget-wrap">
            <Row className="p-0">
              <Col xl="2" md="2" sm="12" className=" widget-sidebar p-0">
                <PostSidebar  getNavState={getNavState}/>
              </Col>
              {navState === 'image' &&            <ImagePostAdd />}
              {navState === 'video' &&               <VideoPostAdd />}
              {navState === 'map' &&               <MapPostAdd />}
              {navState === 'timeline' &&               < TimelinePostAdd/>}
              {navState === 'milestone' &&               < MilestonePostAdd/>}
              {navState === 'text' &&               < TextPostAdd/>}
        
       
            </Row>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddPostModal;
