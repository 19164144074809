import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions";
import { Button, Table } from "reactstrap";
import InviteUserModal from "../../modals/InviteUserModal";
import SettUsersHeader from "./SettUsersHeader";
import { toast } from "react-toastify";
import EditUserModal from "../../modals/EditUserModal";
import { Link } from "react-router-dom";
import { showAlert } from "../../utils/constants";

const SettUsers = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [data, setData] = useState([]);
  const [editedData, setEditData] = useState({});
  const [deleteId, setDeleteId] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const toggleEdit = () => {
    setIsEditOpen(!isEditOpen);
  };

  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const { settings } = selector;
  const { users, usersErr, userLoading } = settings;
  
  const editUser = ({ target: { id } }) => {
    const editData = data.find((o) => o.id === id);
    setEditData(editData);
    if (editData) {
      setIsEditOpen(!isEditOpen);
    }
  };
  const removeUser = ({ target: { id } }) => {
    showAlert(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning"
    ).then((result) => {
      if (result.isConfirmed) {
        setDeleteId(id);
        setIsDeleting(true);
        dispatch(actions.removeUser(id)).then((res) => {
          if (res.status === 200) {
            dispatch(actions.getUsers());
            setIsDeleting(false);
            toast.success("User deleted successfully");
          } else {
            toast.error("Something went wrong");
            setIsDeleting(false);
          }
        });
      }
    });
  };

  useEffect(() => {
    dispatch(actions.getUsers());
    dispatch(actions.handleMenuSettings(true));
    return () => {
      dispatch(actions.handleMenuSettings(false));
    };
  }, [dispatch]);

  useEffect(() => {

    if (users && Object.keys(users).length) {
      setData(users.data);
    }
    if (!users && usersErr && !userLoading) {
      console.log("Something went wrong");
    }
  }, [users ,usersErr ,userLoading]);

  return (
    <>
      <EditUserModal
        isOpen={isEditOpen}
        editData={editedData}
        toggle={toggleEdit}
      />
      <InviteUserModal isOpen={isOpen} toggle={toggle} />
      <SettUsersHeader />
      <div id="settings-teams">
        <div className="team-users">
          <div className="btn-wrap d-flex justify-content-md-end my-3">
            <Button className=" btn-sec" onClick={toggle}>
              ADD USER
            </Button>
          </div>
          <div className="db-table ">
            <Table className="border table-responsive" hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>TEAM AND USERS</th>
                  <th>ACCESS LEVEL</th>
                  <th>TEAMS</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data && data.length && !settings.userLoading
                  ? data.map((user, i) => {
                      let { id, full_name, roles, teams } = user;
                      return (
                        <tr key={id} id={id}>
                          <td className="count">{i + 1}</td>
                          <td className="name">{full_name}</td>
                          <td className="count">
                            {roles.length
                              ? roles.map((o) => o.label).join(", ")
                              : "No Roles"}
                          </td>
                          <td className="count">
                            {teams.length
                              ? teams.map((o) => o.name).join(", ")
                              : "No Teams"}
                          </td>
                          <td className="cta">
                            <Link to="#" id={id} onClick={editUser}>
                              {" "}
                              Edit
                            </Link>
                          </td>
                          <td className="cta">
                            <Link
                              className="text-danger"
                              to="#"
                              id={id}
                              onClick={removeUser}
                            >
                              {deleteId && deleteId === id && isDeleting ? (
                                <div
                                  className="spinner-border text-danger delete__loader"
                                  role="status"
                                ></div>
                              ) : (
                                "Remove"
                              )}
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </Table>
            <div className="d-flex justify-content-center">
              {settings.userLoading ? (
                <div
                  class="spinner-border text-success data__loader"
                  role="status"
                ></div>
              ) : !data.length ? (
                "No data to show"
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettUsers;
