import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions";
import { Button, Table } from "reactstrap";
import { toast } from "react-toastify";
import { showAlert } from "../../utils/constants";
import EditTeamModal from "../../modals/EditTeamModal";
import AddTeamUserModal from "../../modals/AddTeamUserModal";
import InnviteTeamModal from "../../modals/InviteTeamModal";

const SettTeams = () => {
  let [data, setData] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [addTeamId, setAddTeamId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [, setIsDeleting] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [editedData, setEditData] = useState({});

  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const { settings } = selector;
  const { teams, teamsErr, teamsLoading } = settings;

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const toggleEdit = () => {
    setIsEditOpen(!isEditOpen);
  };
  const toggleAdd = () => {
    setIsAddOpen(!isAddOpen);
  };

  const editTeam = ({ target: { id } }) => {
    const editData = data.find((o) => o.id === id);
    setEditData(editData);
    if (editData) {
      setIsEditOpen(!isEditOpen);
    }
  };

  const addUser = ({ target: { id } }) => {
    if (id) {
      setAddTeamId(id);
      setIsAddOpen(!isAddOpen);
    }
  };

  const removeTeam = ({ target: { id } }) => {
    return showAlert(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning"
    ).then((result) => {
      if (result.isConfirmed) {
        setDeleteId(id);
        setIsDeleting(true);
        dispatch(actions.removeTeam(id)).then((res) => {
          if (res.status === 200) {
            dispatch(actions.getTeams());
            setIsDeleting(false);
            toast.success("Team deleted successfully");
          } else {
            toast.error("Something went wrong");
            setIsDeleting(false);
          }
        });
      }
    });
  };

  useEffect(() => {
    dispatch(actions.getTeams());
    dispatch(actions.getUsers());
    dispatch(actions.handleMenuSettings(true));
    return () => {
      dispatch(actions.handleMenuSettings(false));
    };
  }, [dispatch]);

  useEffect(() => {

    if (teams && Object.keys(teams).length) {
      setData(teams.data);
    }
    if (!teams && teamsErr && !teamsLoading) {
      // toast.error("Something went wrong");
    }
  }, [teams, teamsErr, teamsLoading]);

  return (
    <div id="settings-teams">
      <EditTeamModal
        isOpen={isEditOpen}
        editData={editedData}
        toggle={toggleEdit}
      />
      <InnviteTeamModal isOpen={isOpen} toggle={toggle} />
      <AddTeamUserModal id={addTeamId} isOpen={isAddOpen} toggle={toggleAdd} />
      <div className="team-users">
        <div className="btn-wrap d-flex justify-content-md-end my-3">
          <Button className="btn btn-sec" onClick={toggle}>
            ADD TEAM
          </Button>
        </div>
        <div className="db-table">
          <Table className="border table-responsive" hover>
            <thead>
              <tr>
                <th>#</th>
                <th>TEAM</th>
                <th>MEMBERS</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data && data.length && !settings.teamsLoading
                ? data.map((team, i) => {
                    let { id, name, user_count } = team;
                    return (
                      <tr key={id}>
                        <td>{i + 1}</td>
                        <td className="name">{name}</td>
                        <td className="count">{user_count}</td>
                        <td className="cta pointer" id={id} onClick={addUser}>
                          Add User
                        </td>
                        <td className="cta pointer" id={id} onClick={editTeam}>
                          Edit
                        </td>
                        <td
                          className="cta text-danger pointer"
                          id={team["id"]}
                          onClick={removeTeam}
                        >
                          {deleteId && deleteId === team["id"] ? (
                            <div
                              class="spinner-border text-danger delete__loader"
                              role="status"
                            ></div>
                          ) : (
                            "Remove"
                          )}
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </Table>
          <div className="d-flex justify-content-center">
          {settings.teamsLoading ? (
            
              <div
                class="spinner-border text-success data__loader"
                role="status"
              ></div>
          
          ) : !data.length ? (
            "No data to show"
          ) : null}
            </div>
        </div>
      </div>
    </div>
  );
};

export default SettTeams;
