import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../../store/actions/uiAction";
import { Col, Input, Row, Label, Form } from "reactstrap";
const SettSecurity = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.handleMenuSettings(true));
    return () => {
      dispatch(actions.handleMenuSettings(false));
    };
  }, [dispatch]);
  return (
    <div id="settings-security">
      <div className="basic-info text-dark mb-5">
        <Row className=" justify-content-between">
          <Col>
            <div>
              <Row className="align-items-center user-details py-2 border mb-3">
                <Col>
                  <Form className="d-flex align-items-center">
                    <Label className="info__label font-bold" for="name">
                      Password
                    </Label>
                    <Input
                      type="password"
                      name="name"
                      id="name"
                      className="password border-0 text-dark px-md-5 px-"
                      placeholder="Enter your password"
                    />
                  </Form>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SettSecurity;
