import React, { useState } from "react";
import { Button } from "reactstrap";
import Switch from "react-switch";

const ProductHeader = () => {
  const [checked, setChecked] = useState(false);
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  return (
    <div id="product-header">
      <div className="product-header-wrap d-flex justify-content-md-between flex-xl-row flex-md-column align-items-md-center">
        <div className="product-desc">
          <span className="font-15 desc__text">
            <i className=" bi bi-chevron-left"></i>
            <b className="fw-bold"> GREY SWEATSHIRT IN CASHMERE </b> | GRIGIO
            MEDIO - 6771/100
          </span>
        </div>
        <div className="product-cta d-flex align-items-center">
          <Button className="btn-pry btn-save__as">SAVE AS DRAFT</Button>
          <Button className="btn-pry mx-md-4 btn__publish">PUBLISH</Button>
          <div className="custom-control custom-switch d-flex align-items-center">
          
            
            <span className="switch__text">
            {!checked && (
                <span >Online</span>
              )}
            </span>
            &nbsp;
            <label className="d-flex">
              <Switch
                onChange={handleChange}
                checked={checked}
                uncheckedIcon={false}
                checkedIcon={false}
                className="react-switch"
                offColor="#e9ecef"
                onColor="#0095FF"
                onHandleColor="#ffffff"
              />
            </label>
            &nbsp;
            <span className="switch__text">
              {checked && (
                <span className="text-dark fw-bold">Published</span>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductHeader;
