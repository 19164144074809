import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import img1 from "../../assets/img/product-image.jpg";
import CreateProductModal from "../../modals/CreateProductModal";
import { userData } from "../../utils/constants";
import ProductFilter from "./ProductFilter";

const ProductList = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <section id="db-product-list">
      <div className="wrap">
        <ProductFilter />
        <div className="my-4">
          <Row lg="4" md="3" sm="2" xs="1">
            <Col className="my-3 cont">
              <div className="media-box">
                <div className="upload-product-text">
                  <span className="pointer" onClick={toggle}>
                    <i className="bi bi-plus-circle"></i> Add Media
                  </span>
                </div>
              </div>
            </Col>
            {[...Array(10).keys()].map((o) => {
              return (
                <Col className="my-3 cont">
                  <div className="box">
                    <Link to={`/admin/${userData().id}/product/product-info`}>
                      <div className="image">
                        <img src={img1} alt="" />
                      </div>
                    </Link>
                    <div className="desc">
                      <h6 className="m-0 font-13 fw-bold pt-2">
                        CAMICIOLA IN VISCOSE{" "}
                      </h6>
                      <p className="m-0 font-12 fw-light">
                        GRIGIO MEDIO - 6771/100
                      </p>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
      <CreateProductModal isOpen={modal} toggle={toggle} />
    </section>
  );
};

export default ProductList;
