import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./components/utils/translator/en.js";
import de from "./components/utils/translator/de.js";
import fr from "./components/utils/translator/fr.js";
import it from "./components/utils/translator/it.js";

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en,
    de,
    fr,
    it,
  },
  fallbackLng: "en",
  debug: false,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
  },

  react: {
    wait: true,
  },
});

export default i18n;
