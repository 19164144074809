import React from "react";

const NotFOund = () => {
  return (
    <div>
      <h1>Error 404!!!</h1>
    </div>
  );
};

export default NotFOund;
