import React from "react";
import { FormGroup, Input, Col, Label, Row } from "reactstrap";
import Switch from "react-switch";
const RolesSelection = ({ accessLevel, handleSwitch, selectRoles }) => {
  return (
    <Row className="align-items-center">
      <div className="access__level d-flex justify-content-between align-items-center pb-4 pt-2">
        <Label className="info__label">ACCESS LEVEL</Label>
        <div className="custom-control custom-switch d-flex align-items-center">
          <label className="d-flex">
            <Switch
              onChange={handleSwitch}
              checked={accessLevel}
              uncheckedIcon={false}
              checkedIcon={false}
              className="react-switch"
              offColor="#e9ecef"
              onColor="#091C7A"
              onHandleColor="#ffffff"
            />
          </label>
          &nbsp;
          <span className="switch__text">
            {" "}
            {accessLevel ? " Admin" : " User"}
          </span>
        </div>
      </div>

      <Col md="4">
        <FormGroup className="d-flex align-items-center">
          <FormGroup check>
            <Label check className="text-dark invite__check-label fw-normal">
              <Input
                type="checkbox"
                className="invite__check"
                id="content"
                onChange={selectRoles}
                disabled={accessLevel}
              />
              Content
            </Label>
          </FormGroup>
        </FormGroup>
      </Col>

      <Col md="4">
        <FormGroup className="d-flex align-items-center">
          <FormGroup check>
            <Label check className="text-dark invite__check-label fw-normal">
              <Input
                type="checkbox"
                className="invite__check"
                id="billing"
                onChange={selectRoles}
                disabled={accessLevel}
              />
              Billing
            </Label>
          </FormGroup>
        </FormGroup>
      </Col>
      <Col md="4">
        <FormGroup className="d-flex align-items-center">
          <FormGroup check>
            <Label check className="text-dark invite__check-label fw-normal">
              <Input
                type="checkbox"
                className="invite__check"
                id="analytics"
                onChange={selectRoles}
                disabled={accessLevel}
              />
              Analytics
            </Label>
          </FormGroup>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default RolesSelection;
