import React, { useEffect } from "react";
import * as actions from "../../../store/actions/uiAction";
import { Col, Row } from "reactstrap";
import FactoryTab from "./FactoryTab";
import img from "../../assets/img/hero.jpg";
import FactoryBanner from "./FactoryBanner";
import { useDispatch } from "react-redux";

const FactoryCert = () => {
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(actions.handleMenuFactory(true));
      return () => {
        dispatch(actions.handleMenuFactory(false));
      };
    }, [dispatch]);
  
  return (
    <>
      <FactoryBanner />
      <FactoryTab />
      <section id="products-certs">
        <div className="certifications my-5">
          <Row lg="3" md="3" sm="2" xs="1">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((o) => {
              return (
                <Col className="my-3" key={o}>
                  <div className="cert mx-2">
                    <div className="image">
                      <img src={img} alt="certification__images" />
                    </div>
                    <div className="details">
                      <h6 className="title mt-3">Bluesign</h6>
                      <p className="font-14 desc">
                        Bluesign A certification focusing on aspects of consumer
                        safety, water, and air emissions and occupational
                        health. There is also a focus on the reduction of
                        harmful substance usage at the early stages of
                        production.
                      </p>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </section>
    </>
  );
};

export default FactoryCert;
