import React from "react";

import img from "../../assets/img/hero.jpg";
const FactoryBanner = ({location}) => {
  console.log("loc", location);
  return (
    <div id="factory-banner">
      {location && (
        <div className="factory-header text-right text-dark fw-light  px-5 py-2">
          <span className="font-15">
            FACTORY NAME,
            <span className="font-14"> Milan, Italy</span>
          </span>
        </div>
      )}

      <div className="hero-img">
        <img src={img} alt="Factory__banner" />
      </div>
    </div>
  );
};

export default FactoryBanner;
