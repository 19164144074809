import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Col,
  Label,
  Form,
  Row,
} from "reactstrap";
const InviteUserOptModal = ({ isOpen, toggle }) => {
  const [invitState, setInviteState] = useState("options");
  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        id="invite__userOption-modal"
        className="modal-dialog-centered"
      >
        <ModalHeader
          className="text-dark"
          onClick={() => setInviteState("options")}
          toggle={toggle}
        >
          {invitState === "existing"
            ? "INVITE AN EXISTING USER"
            : invitState === "new"
            ? "INVITE NEW USER"
            : "INVITE USER"}
        </ModalHeader>
        <ModalBody>
          {invitState === "options" ? (
            <Col className="mb-4 invite__option">
              <Button
                className="btn-pry d-block  w-100 mb-3"
                onClick={() => setInviteState("existing")}
              >
                INVITE AN EXISTING USER
              </Button>
              <Button
                className="btn-pry d-block w-100"
                onClick={() => setInviteState("new")}
              >
                INVITE A NEW USER
              </Button>
            </Col>
          ) : (
            <Form className="form-wrap">
              <Col className="mb-3">
                <FormGroup>
                  <Label
                    className="info__label pb-2 d-flex justify-content-between"
                    for="name"
                  >
                    <span>TO:</span>
                    <span>TEAMS</span>
                  </Label>
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    className="info__input"
                    placeholder="nonsodaniel07@gmail.com"
                  />
                </FormGroup>
              </Col>
              {invitState === "existing" && (
                <div class="tags invite__user-tags font-12 fw-light mb-3">
                  <span class="tag">
                    User 1 <i class="bi  bi-x info__icon"></i>
                  </span>
                  <span class="tag">
                    User 2 <i class="bi bi-x  info__icon"></i>
                  </span>
                </div>
              )}

              <Label className="info__label">ROLE</Label>
              <Row className="align-items-center   mb-3">
                <Col className="" md="4">
                  <FormGroup className="d-flex align-items-center">
                    <FormGroup check>
                      <Label
                        check
                        className="text-dark invite__check-label fw-normal"
                      >
                        <Input type="checkbox" className=" invite__check" />
                        Read
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </Col>

                <Col className="" md="4">
                  <FormGroup className="d-flex align-items-center">
                    <FormGroup check>
                      <Label
                        check
                        className="text-dark invite__check-label fw-normal"
                      >
                        <Input type="checkbox" className=" invite__check" />
                        Write
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </Col>
                <Col className="" md="4">
                  <FormGroup className="d-flex align-items-center">
                    <FormGroup check>
                      <Label
                        check
                        className="text-dark invite__check-label fw-normal"
                      >
                        <Input type="checkbox" className=" invite__check" />
                        Publish
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Col className="mb-4">
                <FormGroup>
                  <Label className="info__label pb-2" for="name">
                    NOTE:
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    className="info__input font-14 py-2"
                    placeholder="add a personal note to your invitation"
                  />
                </FormGroup>
              </Col>
            </Form>
          )}{" "}
        </ModalBody>
        {invitState !== "options" && (
          <ModalFooter className="justify-content-start">
            <Button className="btn-pry"> INVITE</Button>{" "}
          </ModalFooter>
        )}
      </Modal>
    </div>
  );
};

export default InviteUserOptModal;
