import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import store from "./store/store";
import NotFOund from "./components/utils/components/NotFOund";
import { Container } from "reactstrap";
import Dashboard from "./components/dashboard/Dashboard";
import Auth from "./components/auths/Auth";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Container fluid="true">
          <Switch>
            <Redirect exact from="/" to="/auth/login" />
            {/* <Route path="/projects" component={Projects} /> */}
            <Route path="/auth" component={Auth} />
            <Route path="/admin" component={Dashboard} />
            <Route path="*" component={NotFOund} />
          </Switch>
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            theme="colored"
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Container>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
