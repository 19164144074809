import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as actions from "../../../store/actions/uiAction";
import { Col, Row } from "reactstrap";
import img1 from "../../assets/img/factory.jpg";
import ProductTab from "./ProductTab";
import ProductFilter from "./ProductFilter";
import { useDispatch } from "react-redux";
import UploadProductModal from "../../modals/UploadProductModal";
import ProdDetailModal from "../../modals/ProdDetailModal";



const ProductMedia = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [modalDet, setModalDet] = useState(false);
  const toggle = () => setModal(!modal);
  const toggleDet = () => setModalDet(!modalDet);
  useEffect(() => {
    dispatch(actions.handleMenuProduct(true));
    return () => {
      dispatch(actions.handleMenuProduct(false));
    };
  }, [dispatch]);
  return (
    <div className="">
      <ProductTab />
      <div id="product-media">
        <ProductFilter />
        <div className="my-4">
          <Row lg="4" md="3" sm="2" xs="1">
            <Col className="my-3 cont">
              <div className="media-box">
                <i className="bi bi-three-dots invisible"></i>
                <div className="upload-product-text upload-media">
                  <span className="pointer" onClick={toggle}>
                    <i className="bi bi-plus-circle "></i> Upload a product
                  </span>
                </div>
              </div>
            </Col>
            {[...Array(10).keys()].map((o) => {
              return (
                <Col className="my-3 cont">
                  <div className="box">
                    <i className="bi bi-three-dots dot__icon pointer d-block text-right"></i>
                    <Link to="#" onClick={toggleDet}>
                      <div className="image">
                        <img src={img1} alt="" />
                      </div>
                    </Link>
                    <p className="m-0 font-12 fw-light text-center">
                      imagename.jpeg
                    </p>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
      <UploadProductModal isOpen={modal} toggle={toggle} />
      <ProdDetailModal isOpen={modalDet} toggle={toggleDet} />
    </div>
  );
};

export default ProductMedia;
