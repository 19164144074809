import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions";
import { Button, Col, Row, Table } from "reactstrap";
import { toast } from "react-toastify";
import noImg from '../../assets/img/noImg.jpeg'
const SettInfo = () => {
  const [id, setId] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [profile_picture, setPicture] = useState('')
  const [imgStr, setImgStr] = useState(noImg);
  const [selBtn, setSelBtn] = useState("");

  const [data, setData] = useState({});
  const [teams, setTeams] = useState([]);
  const [, setErrorExist] = useState(false);

  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const { settings } = selector;
  const { companyDetails, companyDetailsErr, companyDetailsLoading } = settings;


  const handleEdit = ({ target }) => {
    setSelBtn(target.name);
    handleSubmit();
  };

  const getFormData = () => {
    return {
      first_name,
      last_name,
      email,
    };
  };

  const handleSubmit = async () => {
    if (first_name === "" || last_name === "" || email === "") {
      setErrorExist(true);
    }
    if (selBtn) {
      let payload = getFormData();
      setErrorExist(false);
      return await dispatch(actions.editUser(payload, id)).then((res) => {
        setSelBtn("");
        let { data, status } = res;
        if (status === 200 && data) {
          dispatch(actions.getCompanyDetails());
          toast.success("Profile Sucessfully updated");
          resetForm();
        } else {
          toast.error("Something went wrong! Kindly retry");
        }
      });
    }
  };
  const resetForm = () => {
    setFirst_name("");
    setLast_name("");
  };

  useEffect(() => {
    dispatch(actions.getCompanyDetails());
    dispatch(actions.getTeams());
    dispatch(actions.handleMenuSettings(true));
    return () => {
      dispatch(actions.handleMenuSettings(false));
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      companyDetails &&
      Object.keys(companyDetails).length &&
      companyDetails?.data?.user
    ) {
      let { id, first_name, last_name, email, profile_picture } = companyDetails?.data?.user;
      setData(companyDetails.data.user);
      setId(id);
      setFirst_name(first_name || "");
      setLast_name(last_name || "");
      setEmail(email || "");
      setTeams(companyDetails?.data?.user?.teams);
      setPicture(profile_picture)
    }
    if (!companyDetails && companyDetailsErr && !companyDetailsLoading) {
      // toast.error("Something went wrong");
    }
  }, [companyDetails, companyDetailsErr, companyDetailsLoading]);
 

  let inputFile = '', fileForm='';
  const previewFile = async () => {
    var file = inputFile.files[0];
    var reader = new FileReader();
    var formdata = new FormData();

    reader.addEventListener(
        "load",
         async() => {
          if(file)  formdata.append("file", file);
          setPicture(reader.result)
           await dispatch(actions.updateUserPicture(formdata, id)).then((res) => {
            let { data, status } = res;
            if (status === 200 && data) {
              dispatch(actions.getCompanyDetails());
              toast.success("Profile Sucessfully updated");
            } else {
              toast.error("Something went wrong! Kindly retry");
            }
          });
        },
        false
    )
    if (file) {
        reader.readAsDataURL(file);
    }
};
const deletePhoto = () =>{
  setImgStr(noImg)
}

  return (
    <div id="settings-info">
      <div className="basic-info text-dark mb-5">
        <h6 className="text-dark pb-3">BASIC INFO</h6>
        <Row className="justify-content-between profile-row">
          {settings.companyDetailsLoading ? (
            <div className="d-flex justify-content-center">
              <div
                class="spinner-border text-success data__loader"
                role="status"
              ></div>
            </div>
          ) : !data ? (
            "No data to show"
          ) : (
            <>
              <Col xl="9">
                <div>
                  <Row className="align-items-center user-details py-2 border mb-3">
                    <Col>
                      <div className="info-title">
                        <h6 className="font-14 m-0"> FIRST NAME</h6>
                      </div>
                    </Col>
                    <Col>
                      <div className="info-name text-center ">
                        <input
                          type="text"
                          className={`form-control text-center font-14 bg-white text-dark info__input ${
                            selBtn === "first" ? "focus" : ""
                          }`}
                          name="first_name"
                          id="first_name"
                          value={first_name}
                          onChange={({ target }) => setFirst_name(target.value)}
                          disabled={selBtn === "first" ? false : true}
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="info-cta text-center">
                        <Button
                          className="btn btn-sec"
                          id={id || ""}
                          name={"first"}
                          onClick={handleEdit}
                        >
                          {/* {btnState('first')} */}
                          {selBtn === "first" ? "SAVE" : "EDIT"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row className="align-items-center user-details py-2 border mb-3">
                    <Col>
                      <div className="info-title">
                        <h6 className="font-14 m-0"> LAST NAME</h6>
                      </div>
                    </Col>
                    <Col>
                      <div className="info-name text-center ">
                        <input
                          type="text"
                          className={`form-control text-center font-14 bg-white text-dark info__input ${
                            selBtn === "last" ? "focus" : ""
                          }`}
                          name=""
                          id=""
                          value={last_name}
                          onChange={({ target }) => setLast_name(target.value)}
                          disabled={selBtn === "last" ? false : true}
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="info-cta text-center">
                        <Button
                          className="btn btn-sec"
                          id={id || ""}
                          name={"last"}
                          onClick={handleEdit}
                        >
                          {selBtn === "last" ? "SAVE" : "EDIT"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row className="align-items-center user-details py-2 border mb-3">
                    <Col>
                      <div className="info-title">
                        <h6 className="font-14 m-0"> EMAIL</h6>
                      </div>
                    </Col>
                    <Col>
                      <div className="info-name text-center ">
                        <input
                          type="text"
                          className={`form-control text-center font-14 bg-white text-dark info__input ${
                            selBtn === "email" ? "focus" : ""
                          }`}
                          name=""
                          id=""
                          value={email}
                          onChange={({ target }) => setEmail(target.value)}
                          disabled={selBtn === "email" ? false : true}
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="info-cta text-center">
                        <Button
                          className="btn btn-sec"
                          id={id || ""}
                          name={"email"}
                          onClick={handleEdit}
                        >
                          {selBtn === "email" ? "SAVE" : "EDIT"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xl="2" className="mb-md-4 ">
                <form 
                 ref={input =>  fileForm = input}
                >

            
                <div className="upload-wrap d-flex flex-column">
                <div className="photo "> 
              <input className="d-none" accept="image/*" type='file'
               id="getFile"
               ref={input =>  inputFile = input}
               onChange={previewFile}
               ></input>
                    <img src={profile_picture ? profile_picture: noImg} alt="User__Picture"  className="image"  />
                  </div>
                  <h6 className="font-14 fw-bold mt-2">PHOTO PROFILE</h6>
                  <p className="font-12 m-0 text-underline pointer" onClick={() =>inputFile.click() }>Upload Photo</p>
                  <p className="font-12 m-0 text-underline pointer" onClick={deletePhoto}>Delete Photo</p>
                </div>
                </form>
              </Col>
            </>
          )}
        </Row>
      </div>
      <div className="db-table ">
        <h6 className="text-dark pb-3">TEAMS</h6>
        <Table className="border table-responsive team-table" hover>
          <thead>
            <tr>
              <th>TEAM AND USERS</th>
              <th className="text-center">ACCESS LEVEL</th>
              <th className="text-center">TEAMS</th>
            </tr>
          </thead>
          <tbody>
            {teams && teams.length && !settings.companyDetailsLoading
              ? teams.map((o) => {
                  let { id, name } = o;
                  return (
                    <tr key={id}>
                      <td className="name">{name}</td>
                      <td className="name text-center">Admin</td>
                      <td className="count text-underline text-center">
                        Marketing, Product…more
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </Table>
        <div className="d-flex justify-content-center">
          {settings.companyDetailsLoading ? (
            <div
              class="spinner-border text-success data__loader"
              role="status"
            ></div>
          ) : !teams.length ? (
            "No Team data to show"
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SettInfo;
