import React from "react";
import { Col, Row } from "reactstrap";
import { NavLink } from "react-router-dom";
const FactoryTab = () => {
  return (
    <div className="tabs border-bottom">
      <div className="tabs-wrap">
        <Row xl="3" md="2" className="justify-content-md-between mx-auto py-3">
          <Col className="tab">
            <NavLink
          
                 className="nav-link"
      
                     to="/admin/1234/factory/factory-story"
            
            >
              OUR STORY
            </NavLink>
          </Col>
          <Col className="tab">
            <NavLink className="nav-link" to="/admin/1234/factory/factory-cert">
              CERTIFICATION
            </NavLink>
          </Col>
          <Col className="tab">
            <NavLink
              className="nav-link"
              to="/admin/1234/factory/factory-made"
            >
              PRODUCTS MADE
            </NavLink>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FactoryTab;
