import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
} from "reactstrap";
import { useDispatch } from "react-redux";
import * as actions from "../../store/actions/settingsActions";
import { toast } from "react-toastify";
import RolesSelection from "../dashboard/settings/RolesSelection";

const EditUserModal = ({ isOpen, toggle, editData }) => {
  const [id, setId] = useState('');
  const [roles, setRoles] = useState([]);
  const [accessLevel, setAccessLevel] = useState(false);
  const [, setErrorExist] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const getFormData = () => {
    return {
      roles: accessLevel ? ["admin"] : [...new Set(roles)],
    };
  };

  const handleSwitch = (nextChecked) => {
    setAccessLevel(nextChecked);
  };

  const selectRoles = ({ target: { id } }) => {
    setRoles([...roles, id]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!accessLevel && !roles.length) {
      setErrorExist(true);
    } else {
     
      let payload = getFormData();
      setErrorExist(false);
      setIsLoading(true)
      return await dispatch(actions.updateUserRole(payload, id)).then(
        (res) => {
          let { data, status } = res;
          if (status === 200 && data) {
            dispatch(actions.getUsers());
            toast.success("User Role Sucessfully updated");
            resetForm();
            toggle();
            setIsLoading(false)
          } else {
            toast.error("Something went wrong! Kindly retry");
            setIsLoading(false)
          }
        }
      );
    }
  };

  const resetForm = () => {
    setRoles([]);
    setAccessLevel(false);
  };

  useEffect(() => {
    if (editData) {
       setId(editData.id);
    } else {
      resetForm();
    }
  }, [editData, id]);

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        id="invite__user-modal"
        className="modal-dialog-centered"
      >
        <ModalHeader className="text-dark" toggle={toggle}>
          UPDATE USER ROLE
        </ModalHeader>
        <ModalBody>
          <Form className="form-wrap">
            <RolesSelection
             setAccessLevel={setAccessLevel}
             accessLevel={accessLevel}
             selectRoles={selectRoles}
             handleSwitch={handleSwitch}
            />
             </Form>
        </ModalBody>
        <ModalFooter className="justify-content-start">
          <Button className="btn-pry" onClick={handleSubmit} disabled={isLoading}>
            {isLoading ? (
              <>
                <div
                  className="spinner-border text-white"
                  style={{ height: "1rem", width: "1rem", color: "white" }}
                  role="status"
                ></div>{" "}
                UPDATING USER ROLE...
              </>
            ) : (
              "UPDATE USER"
            )}
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditUserModal;
